<div class="position-relative">
	<div [ngClass]="{'py-30 px-md-30 border-bottom bk-card-border': !isCustomerDetails && !isMultiStepForm, 'pt-10 mb-10': isMultiStepForm}" [id]="section?.id" [formGroup]="customerGroup" *ngIf="settings && section">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isCustomerDetails" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Existing customer cards -->
		<div class="mb-20" *ngIf="!isCustomerDetails && customerGroup.value['customer_type'] == 'existing customer' && addresses && addresses.length > 0">
			<div class="form-check form-check-inline me-25 mb-10 input--checked">
				<input id="existing" class="form-check-input" #existingAddress type="radio" value="existing_address" [(ngModel)]="addrType" [ngModelOptions]="{standalone: true}" (change)="addrTypeChange('existing')" [disabled]="isRadioDisabled">
				<label for="existing" class="form-check-label text-capitalize bk-form-check-label" [id]="section?.existing_address_id">{{section?.existing_address ? section.existing_address : initServ.appStr?.text?.useExisting}}</label>
			</div>
			<div class="form-check form-check-inline me-25 mb-10 input--checked">
				<input id="new" class="form-check-input" #newAddress type="radio" value="new_address" [(ngModel)]="addrType" [ngModelOptions]="{standalone: true}" (change)="addrTypeChange('new')" [disabled]="isRadioDisabled">
				<label for="new" class="form-check-label text-capitalize bk-form-check-label" [id]="section?.new_address_id">{{section?.new_address ? section.new_address : initServ.appStr?.text?.newAddress}}</label>
			</div>
		</div>
		<!-- Existing address -->
		<div class="row" *ngIf="customerGroup.value['customer_type']=='existing customer' && addresses && addresses.length > 1 && addrType == 'existing_address'">
			<div class="col-sm-12 col-md-12 mb-30">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'saved_addresses', section:section, translated:true}"></ng-container>
				<select class="form-select bk-select" [ngClass]="{'form-select-lg': isMultiStepForm}" [(ngModel)]="selectedAddr" [ngModelOptions]="{standalone: true}" (ngModelChange)="setSelectedAddr(selectedAddr)" [disabled]="isRadioDisabled">
					<option *ngFor="let addr of addresses" [ngValue]="addr" class="bk-select-option">
						<span *ngIf="addr?.apt">{{'Apt' | translate}} - {{addr.apt}} </span>{{addr?.address}}
					</option>
				</select>
			</div>
		</div>
		<!-- Address block -->
		<div class="row">
			<div id="address-section-id" class="col-sm-6 mb-30" [ngClass]="isMultiStepForm ? 'col-md-9' : 'col-md-6'">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'address', section:section}"></ng-container>
				<input class="form-control bk-input" [ngClass]="{'is-invalid': customerGroup.controls['address'].touched && customerGroup.controls['address'].errors, 'form-control-lg': isMultiStepForm}"  type="text" placeholder="{{section?.address_placeholder}}" [(ngModel)]="address" [ngModelOptions]="{standalone: true}" (focusout)="getAddr($event)"  (addressChange)="setAddr($event, 'zipcode')" (addressChange)="setAddr($event, 'city')" (addressChange)="setAddr($event,'state')" bk-google-places-autocomplete [attr.disabled]="isAddrDisabled" [readonly]="isAddrReadonly"/>
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="customerGroup.controls['address'].touched && (customerGroup.controls['address']?.errors?.required || customerGroup.controls['address']?.errors?.pattern)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="customerGroup.controls['address']?.errors?.required"> {{initServ.appStr?.errorMsg?.enterAddr | translate}} </ng-container>
					<ng-container *ngIf="customerGroup.controls['address']?.errors?.pattern"> {{initServ.appStr?.errorMsg?.specialChars | translate}} </ng-container>
				</div>
			</div>
			<!-- Apt -->
			<div class="col-sm-6 mb-30" [ngClass]="isMultiStepForm ? 'col-md-3' : 'col-md-6'">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'apt', section:section}"></ng-container>
				<input class="form-control bk-input" [ngClass]="{'form-control-lg': isMultiStepForm}" type="text" placeholder="{{section?.apt_placeholder}}" formControlName="apt" [attr.disabled]="isAddrDisabled" [readonly]="isAddrReadonly" [class.is-invalid]="customerGroup.controls['city'].errors && customerGroup.controls['city'].touched">
				<!-- Error -->
				<ng-container *ngIf="customerGroup.controls['apt'].touched">
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['apt']?.errors?.pattern" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['apt']?.errors?.shortTextValidationCheck" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.shortMaxLength | translate}}</div>
				</ng-container>
			</div>
		</div>
		<div class="row">
			<!-- City -->
			<div class="col-sm-4 col-md-4 mb-30" [ngClass]="{'d-none': !splitAddr}">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'city', section:section}"></ng-container>
				<input id="city" class="form-control bk-input" [ngClass]="{'form-control-lg': isMultiStepForm}" type="text" placeholder="{{section?.city_placeholder}}" formControlName="city" (focusout)="focusOutAddr('city')" [attr.disabled]="isAddrDisabled" [readonly]="isAddrReadonly" [class.is-invalid]="customerGroup.controls['city'].errors && customerGroup.controls['city'].touched">
				<!-- Error -->
				<ng-container *ngIf="customerGroup.controls['city'].touched">
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['city']?.errors?.pattern" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['city']?.errors?.shortTextValidationCheck" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.shortMaxLength | translate}}</div>
				</ng-container>
			</div>
			<!-- State -->
			<div class="col-sm-4 col-md-4 mb-30" [ngClass]="{'d-none': !splitAddr}">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'state', section:section}"></ng-container>
				<input id="state" class="form-control bk-input" [ngClass]="{'form-control-lg': isMultiStepForm}" type="text" placeholder="{{section?.state_placeholder}}" formControlName="state" (focusout)="focusOutAddr('state')" [attr.disabled]="isAddrDisabled" [readonly]="isAddrReadonly" [class.is-invalid]="customerGroup.controls['state'].errors && customerGroup.controls['state'].touched">
				<!-- Error -->
				<ng-container *ngIf="customerGroup.controls['state'].touched">
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['state']?.errors?.pattern" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['state']?.errors?.shortTextValidationCheck" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.shortMaxLength | translate}}</div>
				</ng-container>
			</div>
			<!-- Customer zipcode -->
			<div class="col-sm-4 col-md-4 mb-30" [ngClass]="{'d-none': !splitAddr && !isShowZipcode}">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'zipcode', section:section}"></ng-container>
				<input id="postal_code" [ngClass]="{'is-invalid': customerGroup.controls['customer_zipcode'].touched && customerGroup.controls['customer_zipcode'].errors}" class="form-control bk-input" [ngClass]="{'form-control-lg': isMultiStepForm}" type="text" placeholder="{{section?.zipcode_placeholder}}" formControlName="customer_zipcode" (focusout)="focusOutAddr('zipcode')" [attr.disabled]="isAddrDisabled" [readonly]="isAddrReadonly">
				<!-- Error -->
				<ng-container *ngIf="customerGroup.controls['customer_zipcode'].touched">
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['customer_zipcode']?.errors?.required" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.enterZipcode | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['customer_zipcode']?.errors?.pattern" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['customer_zipcode']?.errors?.shortTextValidationCheck" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.shortMaxLength | translate}}</div>
				</ng-container>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Label template -->
<ng-template #labelTemp let-field="field" let-section="section" let-translated="translated">
	<ng-container *ngIf="translated else noTranslated">
		<label class="form-label bk-form-label" *ngIf="section[field]" [id]="section[field+'_id']" [innerHTML]="section[field] | translate | safeHtml"></label>
	</ng-container>
	<ng-template #noTranslated>
		<label class="form-label bk-form-label" *ngIf="section[field]" [id]="section[field+'_id']" [innerHTML]="section[field] | safeHtml"></label>
	</ng-template>
</ng-template>
