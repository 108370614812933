/* eslint-disable max-depth */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
// External lib
import { Md5 } from 'ts-md5';
// Services
import { RenderComponentServ, ApiServ, InitServ, LoaderServ, UtilServ, BkngFormServ, StyleServ, SectionServ } from '../Services';
import { CustomValidators } from '../Global/GlobalDefault';
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP, AUTH_KEY } from '../Constants';
// Popups
import { BookingAddedSuccessfullyMsgComponent } from './index';

@Injectable({
	providedIn: 'root'
})
export class AddBkngServ {
	// private variables
	private appData: any;
	private _bookingObj: any;
	currentUser: any;
	// Private variables
	private destroy = new Subject<void>();
	// Getter function
	// @returns data for second step
	get bookingVar(): any { return this._bookingObj; }
	singleFormSett: any;
	// eslint-disable-next-line max-params
	constructor(private rcServ: RenderComponentServ, public apiServ: ApiServ, private initServ: InitServ, private loader: LoaderServ, private utilServ: UtilServ, private bkngFormServ: BkngFormServ, private router: Router, private frmBldr: FormBuilder, private dialog: MatDialog,private styleServ : StyleServ, private secServ: SectionServ, private custValidators: CustomValidators) {
		this.appData = this.initServ.appData; // App data
		// this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.currentUser = this.utilServ.appLocalStorage();
			}
		});
	}
	/**
	 * set booking variables: loaderId, industrySlug, industry, form, combination, containerId
	 */
	set setBkngVariables(data: any) {
		this._bookingObj = data;
	}

	/**
	 * Load the page data based on page slug
	 * @param slug: page slug
	 */
	public buildPage(data: any) {
		this.getIndFormSett(data);
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any, data: any, pageSlug: string = ''){
		if(this.apiServ.checkAPIRes(res) && res.data){
			let pageData: any = res.data;
			if(pageData.column_sections && (pageData.column_sections).length > 0){
				pageData.added_sections = (pageData.added_sections).filter( (el: any) => !(pageData.column_sections).includes( el ) );
			}
			// Set the page settings
			if(res.data && res.data.settings){
				let pageSett: any = res.data.settings;
				if(data && !data?.isEmbed && pageSett.redirect_status && pageSett.redirect_status == 'yes' && (pageSett.redirect_link_to == 'page' || pageSett.redirect_link_to == 'web') && pageSett.redirect_link_url){
					this.utilServ.redirectPageURL(pageSett); // Redirect url
				} else {
					this.rcServ.setPageData = JSON.parse(JSON.stringify(res.data));
					if(data && !data?.isEmbed){
						// Theme popups
						this.rcServ.loadThemePopups(pageSett);
						this.initServ.setCurrPageIdInBody(pageSett.booknow_page_id);
					}
					// Page title
					this.rcServ.setPageTitle(pageSett);
					// Add tracking codes added in page settings
					this.rcServ.addTrackingCodes(pageSett);
					let pageId: any;
					if(res.data.slug == 'booking' && res.data.settings && res.data.settings.booknow_page_id){
						pageId = res.data.settings.booknow_page_id;
					} else{
						pageId = res.data._id;
					}
					this.utilServ.setPageSett(pageId, res.data.settings); // page settings
				}
			}
			// Reset the page api hit count;
			this.rcServ.setPageApiHitCount = 0;
			this.createDynamicComponent(data);
			this.initServ.firstPageData = null;
			this.initServ.firstPageSlug = null;
		} else {
			this.utilServ.redirectBrokenUrl(pageSlug);
		}
		// if you need to scroll back to top, here is the right place
		setTimeout(() => {
			window.scrollTo({top: 0, behavior: 'smooth'});
		}, 1000);
		this.loader.hide(this.bookingVar?.loaderId);
	}

	/**
	 * Loop through all sections of the page
	 */
	public createDynamicComponent(data: any=null): void {
		let i = 0;
		if(this.rcServ.pageData && this.rcServ.pageData.added_sections && (this.rcServ.pageData.added_sections).length > 0) {
			if(data && !data?.isEmbed){
				this.bookingVar.vcRef.clear();
			}
			let bookingSummary: any = this.rcServ.pageData?.booking_summary;
			this.removeSections();
			for(let secId of this.rcServ.pageData.added_sections) {
				if(secId != bookingSummary){
					i = i + 1;
					this.createComponent(secId, i, data);
				}
			}
		}
	}
	/**
	 * Load components using "view reference"
	 * @param secId Id of section which we want to add on the page
	 * @param i index, so we can create unique id of wrapper for each section
	 */
	private createComponent(secId: string, i: number, data: any) {
		let secSlug: string = this.rcServ.pageData.section_settings[secId].slug;
		if(secSlug && secSlug != 'short_form' && secSlug != 'embed_booking_form'){
			if(data && data?.bannerSecStatus){
				this.renderComponent(secId,secSlug,i);
			} else {
				if(secSlug == 'form'){
					this.renderComponent(secId,secSlug,i);
				}
			}
		}
		this.loader.hide(this.bookingVar?.loaderId);
	}
	/**
	 * Render the component
	 * @param secId: section id
	 * @param secSlug: section slug
	 * @param i: index
	 */
	private renderComponent(secId: any, secSlug: any, i: any){
		this.rcServ.renderComponent(secId, secSlug, this.bookingVar.vcRef);
		var div = document.createElement('div');
		div.setAttribute('class', 'bk-element-container-bkng position-relative');
		div.setAttribute('id', 'component-' + i);
		div.appendChild(this.rcServ.componentRef.location.nativeElement);
		let elem: any = document.getElementById(this.bookingVar?.containerId);
		if(elem){
			elem?.appendChild(div);
		}
	}
	/**
	 * Remove the previous sections child
	 */
	public removeSections(){
		var paras: any = document.getElementsByClassName('bk-element-container-bkng');
		if(paras && paras.length > 0){
			while (paras[0]) {
				paras[0].parentNode.removeChild(paras[0]);
			}
		}
	}
	/**
	 * Check the params availability
	 * @param queryParams
	 * @returns
	 */
	public checkParamAvail(queryParams: any): boolean {
		if(queryParams){
			for(var key in queryParams) {
				/** To build the pricing param for form 1 **/
				if (key && key.includes("pricing_parameter[", 0) || key.includes("area_parameter[", 0) || key.includes("extras[", 0) || key.includes("excludes[", 0)) {
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Create pricing params from url query params.
	 * @param embededPrefilledParams
	 * @returns array
	 */
	public createParamObjFromUrl(embededPrefilledParams: { [x: string]: string | number; }): any {
		let pricingParams = [];
		let extras = [];
		let excludes = [];
		for(var key in embededPrefilledParams) {
			/** To build the pricing param for form 1 **/
			if (key && key.includes("pricing_parameter[", 0)) {
				let paramId = key.slice(key.indexOf('[')+1, key.indexOf(']'));
				let obj = {id: +paramId, quantity: +embededPrefilledParams[key]}
				if(obj.quantity){
					pricingParams.push(obj)
				}
			} else {
				/** To build the pricing param for form 1 **/
				if (key && key.includes("area_parameter[", 0)) {
					let paramId = key.slice(key.indexOf('[')+1, key.indexOf(']'));
					let obj = {id: +paramId, quantity: +embededPrefilledParams[key]}
					if(obj.quantity){
						pricingParams.push(obj)
					}
				}
			}
			/** To build the extras params **/
			if (key && key.includes("extras[", 0)) {
				let extraId = key.slice(key.indexOf('[')+1, key.indexOf(']'));
				let obj = {id: +extraId, quantity: +embededPrefilledParams[key]}
				if(obj.quantity){
					extras.push(obj)
				}
			}
			/** To build the excludes params **/
			if (key && key.includes("excludes[", 0)) {
				let excludeId = key.slice(key.indexOf('[')+1, key.indexOf(']'));
				let obj = {id: +excludeId, quantity: +embededPrefilledParams[key]}
				if(obj.quantity){
					excludes.push(obj)
				}
			}
		}
		return [pricingParams, extras, excludes];
	}
	/**
	 * Check if industry id exist in the system.
	 * @param industryId
	 * @returns boolean
	 */
	public isIndustryExist(industryId: string | number): boolean {
		if(this.appData && this.appData.industries && (this.appData.industries).length > 0){
			for(let industry of this.appData.industries){
				if(industry.id == +industryId){
					if(industry.status == 1 && this.utilServ.checkIndustryStatusForCust(industry)){
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Check if form id exist in the system.
	 * @param industryId
	 * @param formId
	 * @returns boolean
	 */
	public isFormExist(industryId: string | number, formId: string | number): boolean {
		if(this.appData && this.appData.industries && (this.appData.industries).length > 0){
			for(let industry of this.appData.industries){
				if(industry.id == +industryId){
					if(industry.forms && (industry.forms).length > 0){
						for(let form of industry.forms){
							if(form.form_id == +formId && form.form_status == 1){
								return true;
							}
						}
					}
				}
			}
		}
		return false;
	}
	/**
	 * Get the combination form data
	 * @returns object
	 */
	public getCombinationForm(): any {
		let obj: any = {
			formId:null,
			combination: null
		}
		if(this.bkngFormServ.industryFormSett){
			// Industry data (combination & single)
			if(this.bkngFormServ.industryFormSett.combined_combination && (this.bkngFormServ.industryFormSett.combined_combination).length > 0){
				if(this.bkngFormServ.industryFormSett.combined_combination[0].prequalifier_data[0].form_id == 0){
					if(this.bkngFormServ.industryFormSett.single_combination && (this.bkngFormServ.industryFormSett.single_combination).length > 0){
						for(let combi of this.bkngFormServ.industryFormSett.single_combination){
							if(combi.id == this.bkngFormServ.industryFormSett.combined_combination[0].prequalifier_data[0].combination_id){
								obj.combination = combi;
								obj.formId = obj.combination.prequalifier_data[0].form_id;
							}
						}
					}
				} else{
					obj.formId = this.bkngFormServ.industryFormSett.combined_combination[0].prequalifier_data[0].form_id;
				}
			} else if(this.bkngFormServ.industryFormSett.single_combination){
				obj.formId = this.bkngFormServ.industryFormSett.single_combination[0].prequalifier_data[0].form_id;
			} else{
				// Default industry
				if(this.bkngFormServ.industryFormSett.industries && (this.bkngFormServ.industryFormSett.industries).length > 0){
					let isDefault: boolean = false;
					for(let form of this.bkngFormServ.industryFormSett.industries){
						if(form.is_default){
							isDefault = true;
							obj.formId = form.form_id;
							break;
						}
					}
					if(!isDefault){
						obj.formId = this.bkngFormServ.industryFormSett.industries[0].form_id;
					}
				}
			}
		}
		return obj;
	}

	/**
	 * Build the add booking form group
	 * @param currentUser logged user
	 * @param industryId
	 * @param formId
	 * @returns from group
	 */
	// eslint-disable-next-line max-lines-per-function
	public addBkngFormGroup(currentUser: any, industryId: any, formId: any): any{
		let currentUserIp = this.initServ.ipAddress;
		let currentUserId = null;
		if(currentUser){
			currentUserIp = currentUser.Ip;
			currentUserId = currentUser.id;
		}
		let value = {
			draft_id: [null],
			uid: [null],
			created_by: [currentUserId],
			industry_id: [industryId],
			form_id: [formId],
			address_id: [null],
			charge_onetime_cancellation: [],
			zipcode: [null, [Validators.required]],
			location_type: [],
			merchant_address_picture: [],
			merchant_location_name: [],
			adjust_price: [],
			price_adjustment_note: [],
			time_adjustment_note: [],
			adjusted_price: [null],
			adjust_time: [],
			adjusted_time: [],
			adjust_time_hourly: [],
			admin_email_reminder: [],
			admin_email_reminder_date: [],
			admin_email_reminder_day_before: [],
			admin_email_reminder_hours_before: [],
			admin_email_reminder_message: [],
			alert_charge_now: [],
			alert_charge_text: [],
			alert_reminder_admin: [],
			booking_tax_rate: [],
			booking_tax_type: [],
			same_day_booking: [false],
			pricing_parameter: [],
			area_parameter: [],
			items: this.frmBldr.array([]),
			coupon: this.frmBldr.group({
				id: [],
				code: [],
				discount: [],
				discount_type: ["code"],
				provider_discount_type: [null],
				provider_discount: [null],
				half_discount: [false],
				applicable_with_gift_card: [null],
				applicable_with_referral: [null],
				recurring: [],
				apply_on_bookings: [],
				min_order: [],
				max_order: [],
				apply_order_on_adjusted: [],
				apply_freq_disc_with_coupon: [],
				override_freq_cancel_settings: [],
				charge_onetime_cancellation: [],
				cancellation_after_appt: [],
				prepay_rec_booking: [],
				prepay_bookings_count: []
			}),
			before_priceable_fields_price : [],
			service_price : [],
			service_total: [],
			expedited_amount: [null],
			frequency_discount_amount: [],
			frequency_discount_value: [],
			frequency_discount_type: [],
			discounted_total: [],
			provider_discounted_total: [],
			total_before_coupon_discount: [],
			booking_tax: [],
			booking_tax_api: [],
			total: [],
			frequency: [],
			base_frequency_id: [],
			frequency_discount_on_bookings: [],
			day_discount: this.frmBldr.group({
				discount: [],
				discount_type: ['fixed'],
				apply_to_all: ['true']
			}),
			gift_card: this.frmBldr.group({
				id: [],
				code: [],
				discount: [],
				gift_card_total: [],
				previously_used: [],
				status: [null]
			}),
			is_giftcard_applied: [false],
			is_referral_applied: [false],
			is_coupon_applied: [false],
			is_discount_applied: [false],
			is_previous_day: [false],
			is_partial_cleaning: [],
			key_info: ['at_home'],
			key_with_provider: [false],
			key_note: [],
			parking: this.frmBldr.group({
				total_amount: [null],
				each_member_amount: this.frmBldr.array([]),
				save_to_booking: []
			}),
			provider_service_fee: this.frmBldr.array([]),
			provider_type: ['random_provider'],
			provider_length: [],
			length: [],
			original_length: [],
			reduced_length: [],
			referral_amount: [],
			referral_discount: [],
			referral_user_id: [],
			service_category: [],
			can_decline_job: [],
			is_service_hourly: [],
			service_hourly_value: [0],
			service_price_overridden: [false],
			service_price_overridden_value: [null],
			override_service_total : [],
			overridden_service_total : [null],
			occurrence: [],
			frequency_type: [],
			frequency_repeat_slug: [],
			final_amount: [null],
			ip_address: [currentUserIp],
			// TODO: In response to issues with existing bookings, we've temporarily removed the maximum length validation from text editors. This change allows bookings to be rescheduled or duplicated even if the text exceeds the previous length limit. However, we acknowledge that this is not an ideal long-term solution. Moving forward, we'll explore alternative methods to handle text validation while ensuring a seamless user experience. This might involve implementing a different validation approach or finding more effective ways to manage text content without strict length constraints.
			// special_notes : [null, [this.custValidators.extraLongTextValidate]],
			special_notes : [null],
			location_id: [null,[Validators.required]],
			base_location_id: [],
			tip: this.frmBldr.group({
				amount_type: [],
				total_amount: [null],
				each_member_amount: [],
				save_to_booking: []
			}),
			booking_note: [],
			provider_note: [],
			note_from_provider: [],
			private_customer_note: [],
			customer: this.frmBldr.group({
				id: [],
				first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				email_id: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP), this.custValidators.shortTextValidate]],
				password: [null, [Validators.required]],
				phone_number: [null],
				send_sms_notification: [this.utilServ.getSendSmsNotifDefVal()],
				emails: this.frmBldr.array([]),
				phone_numbers: this.frmBldr.array([]),
				address: [null, [Validators.required] ],
				short_address: [null],
				apt : [null, [Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				customer_zipcode : [null, [Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				city : [null, [Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				state : [null, [Validators.pattern(TEXT_REG_EXP), this.custValidators.shortTextValidate]],
				created_by: [null],
				customer_type: ["new customer"],
				is_customer_new: [false],
				invite_by: [],
				user_agent: [],
				address_id: [null],
				profile_pic: [null]
			}),
			address: this.frmBldr.group({
				address: [],
				city: [],
				state: [],
				zipcode: [],
				apt: [],
				short_address: [],
				latlng: this.frmBldr.group({
					lat:[0],
					lng:[0]
				})
			}),
			bonus: this.frmBldr.group({
				status: [],
				total_amount: [],
				each_member_amount: this.frmBldr.array([]),
				reason: []
			}),
			arrival_date: [null, [Validators.required]],
			booking_date: [],
			recurring_dates: [],
			arrival_time: [null, [Validators.required]],
			provider_details: this.frmBldr.array([]),
			provider_wages: this.frmBldr.array([]),
			provider_ids: this.frmBldr.array([]),
			temp_provider_ids: [],
			provider_category: [],
			non_available_provider: [false],
			pair_availability: this.frmBldr.array([]),
			service_provider_pay_overridden: [false],
			provider_pay_overridden_value: [null],
			provider_pay_overridden_type: [],
			payment_method: [],
			pay_with_cc: [],
			charge_service_fee: [false],
			service_fee: [],
			is_service_fee_taxable: [],
			automatically_accept_job: [false],
			is_booking_unassigned: [false],
			exclude_day_discount: [false],
			exclude_reschedule_fee: [false],
			exclude_service_fee: [false],
			exclude_expedited_charge: [false],
			exclude_cancellation_fee: [false],
			exclude_cancellation_fee_after_first_booking: [false],
			exempt_sales_tax: [false],
			exclude_minimum: [false],
			override_provider_pay: [false],
			override_each_prvdr_time : [false],
			each_prvdr_time : this.frmBldr.array([]),
			hide_checklist: [false],
			dont_send_notification: [false],
			dont_send_notification_provider: [false],
			notify_customer_for_time: [false],
			custom_fields: this.frmBldr.group({}),
			custom_fields_checkbox: this.frmBldr.group({}),
			credit_card: this.frmBldr.group({
				token: [],
				location_account_type: [],
				dataValue: [''],
				dataDescriptor: ['']
			}),
			send_credit_card_link: [],
			newCreditCardDetail: this.frmBldr.group({ }),
			start_time: [],
			start_time_offset: [],
			booking_priority: [],
			spot_count: [1],
			is_marketplace: [false],
			wages_on_adjusted: ['no'],
			apply_provider_discount: [false],
			is_waiting_list: [false],
			accept_terms_conditions: [false],
			spot_type: ['schedule'],
			arrival_window:[],
			continue_with_same_provider: [false],
			recurring_total: [],
			available_providers_for_debugging: [],
			exclude_extra_time : [],
			exempt_extras_price : [],
			provider_skip_dates : [],
			include_in_freq_fields_price : [],
			exempt_from_freq_fields_price : [],
			exclude_fields_time : [],
			add_fields_time : [],
			non_taxable_fields_price : [],
			after_disc_total_fields_price : [],
			inc_in_freq_exc_frm_prvdr : [],
			add_sep_exc_frm_prvdr : [],
			exempt_frm_freq_exc_frm_prvdr : [],
			card_last4_digits:['']
		}
		return value;
	}
	/**
	 * Add user email to 'MailChimp` server
	 * @param formValues: Form values
	 */
	public addEmailToMailChimp(formValues: any, customData: any = null){
		let email: string = this.utilServ.getValidEmail(formValues ? formValues.customer.email_id : customData?.email_id);
		if(email && email.length > 0){
			let data : any = {
				email_id : email,
				list_name : 'card_abandonment',
				industry_id : formValues ? formValues.industry_id : customData?.industry_id,
				location_id : (formValues && formValues.base_location_id) ? formValues.base_location_id : 0
			}
			if(customData || !formValues.uid){
				this.apiServ.callApi('POST', 'MailChimpMoveToList', data).pipe(takeUntil(this.destroy)).toPromise();
			}
		}
	}
	/**
	 * Add user email to campaign contact form
	 * @param formValues: Form values
	 */
	public addContactToCampaign(formValues: any, customData: any = null): void {
		let data: any = {
			is_abandoned : true,
			industry_id : formValues ? formValues.industry_id : customData?.industry_id,
			form_id : formValues ? formValues.form_id : null,
			location_id : (formValues && formValues.location_id) ? +formValues.location_id : null,
			base_location_id : (formValues && formValues.base_location_id) ? +formValues.base_location_id : null,
			first_name: (formValues && formValues.customer && formValues.customer.first_name) ? formValues.customer.first_name : '',
			last_name: (formValues && formValues.customer && formValues.customer.last_name) ? formValues.customer.last_name : '',
			email: (formValues && formValues.customer && formValues.customer.email_id) ? formValues.customer.email_id : (customData?.email_id),
			phone: (formValues && formValues.customer && formValues.customer.phone_number) ? this.utilServ.phoneUnmask(formValues.customer.phone_number) : '',
			zip_code: formValues ? formValues.zipcode : null,
			frequency: formValues ? formValues.frequency : null,
			service_category: formValues ? formValues.service_category : null,
			pricing_parameters: formValues ? formValues.pricing_parameter : null,
			area_parameter : (formValues && formValues.area_parameter && formValues.area_parameter.id) ? formValues.area_parameter.id : null,
			items: formValues ? formValues.items : null
		}
		if(data.email){
			data.email = (data.email).trim();
		}
		var date = new Date().getTime();
		let dateString = date ? date.toString() : '';
		let token = AUTH_KEY + "+" + dateString;
		let tokenMd5String = Md5.hashStr(token);
		let tokenString: any = tokenMd5String.toString();
		let header = { 'Content-Type': 'application/json','Auth-token' : tokenString, 'Timestamp' : dateString}
		this.apiServ.callApi('POST', 'CampaignContacts', data, header).pipe(takeUntil(this.destroy)).toPromise();
	}

	/**
	 * Toggle responsive summary.
	 * Only work multi step form
	 */
	public respSummary(): void{
		document.documentElement.classList.toggle('overflow-hidden');
		let headerElms = document.querySelectorAll('.multistep__header--elements');
		if(headerElms && headerElms.length > 0){
			headerElms.forEach(element => element.classList.toggle('active-steps'));
			document.documentElement.classList.toggle('responsive-summary--active');
		}
	}

	/**
	 * Refresh the slider
	 */
	public refreshMultiStepSlider(): void {
		// setTimeout(()=>{
		// 	let calSliderEl:any = document.getElementById('cal-slider');
		// 	if(calSliderEl){
		// 		calSliderEl.slick('refresh');
		// 	}
		// 	let pckgSlideEl = document.querySelectorAll('.packages-slide-wrapper');
		// 	if(pckgSlideEl && pckgSlideEl.length > 0){
		// 		pckgSlideEl.forEach((element:any) => element.slick('refresh'));
		// 	};
		// }, 1000);
	}
	/**
	 * Function for booking added success message popup.
	 */
	 public bkngAddedSuccMsgPopup(data: any) {
		let ref: MatDialogRef<BookingAddedSuccessfullyMsgComponent>
		ref = this.dialog.open(BookingAddedSuccessfullyMsgComponent);
		ref.componentInstance.data = data;
		return ref.afterClosed();
	}
	/**
	 * Check the extra and custom fields status for build the multi step
	 * @param data: multi step data
	 * @param settings: settings
	 * @param selectedLocation : selected location
	 * @param selectedServiceType: selected service type
	 * @param selectedFrequency: selected frequency
	 * @param BKFrm: booking form
	 * @param prefilledFormParams: prefilled form parameters
	 * @param bookingType: booking type
	 * @param customFieldsStatus: custom fields status
	 * @returns boolean
	 */
	public checkExtraCustomFieldsOnMultiStep(data: any, settings: any, selectedLocation: any,selectedServiceType: any, selectedFrequency: any, BKFrm: any, prefilledFormParams: any, bookingType: any, customFieldsStatus: any, section: any, pageSett: any): boolean {
		let flag: boolean = false;
		if(data.includes('extras') || data.includes('custom_fields_step_one') || data.includes('custom_fields_step_two')){
			if(settings){
				if(data.includes('extras') && this.bkngFormServ.isExtrasAvailable(settings.extras, selectedLocation, selectedServiceType, selectedFrequency, BKFrm.value, prefilledFormParams?.extras, settings, bookingType) && this.secServ.checkEleStatus(pageSett, section?.id)){
					flag = true;
				}else{
					if(data.includes('custom_fields_step_one')){
						if(customFieldsStatus && customFieldsStatus.step_one){
							flag = customFieldsStatus.step_one;
						}
					}
					if(!flag && data.includes('custom_fields_step_two')){
						if(customFieldsStatus && customFieldsStatus.step_two){
							flag = customFieldsStatus.step_two;
						}
					}
				}
			}
		}
		return flag;
	}
	/**
	 * Check the  key info and special note status for build the multi step
	 * @param data: data
	 * @param BKFrm: Booking form
	 * @param settings: settings
	 * @returns boolean
	 */
	public checkKeyInfoSpecialNoteOnMultiStep(data: any, BKFrm: any, section: any, pageSett: any): boolean {
		let flag: boolean = false;
		if(!flag && (data.includes('key_info') || data.includes('special_notes'))){
			if(data.includes('key_info') && BKFrm.value['location_type'] == 'SA' && this.secServ.checkEleStatus(pageSett,section?.key_info?.id)){
				flag = true;
			}else{
				if(data.includes('special_notes') && this.secServ.checkEleStatus(pageSett, section?.special_notes?.id)){
					flag = true
				}
			}
		}
		return flag;
	}
	/**
	 * Hit API to get single form settings
	 * @param data
	 */
	private getIndFormSett(data: any): void {
		if(this.bookingVar.industry && this.bookingVar.form){
			// if(data?.quoteId){
			// 	console.log('only draft quote');
				this.apiServ.callApiWithPathQueryVars('GET', 'SingleFormSett', [this.bookingVar.industry, this.bookingVar.form]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.singleFormSettRes(res, data));
			// } else {
			// 	console.log('only add and rescheduled quote');
			// 	this.singleFormSettRes(null, data);
			// }
		}
	}
	/**
	 * Check response of single form settings API
	 * @param res Response of API
	 * @param data
	 */
	private singleFormSettRes(res: any, data: any): void{
		if(this.apiServ.checkAPIRes(res)){
			this.singleFormSett = res.data;
		}
		this.buildPageData(data);
	}
	/**
	 * Load the page data based on page slug
	 * @param data
	 */
	private buildPageData(data: any): void {
		// Page slug
		let pageSlug = this.router.url.split(/[/?#]/)[1];
		// Set loader id for hide the loader in case of api handle error.
		this.apiServ.setLoaderId(this.bookingVar?.loaderId);
		this.loader.show(this.bookingVar?.loaderId);
		if(data && data?.isEmbed){
			pageSlug='booknow';
		}
		// Query params
		let queryParams: any = {slug:pageSlug,language:this.initServ.savedLng, industry: this.bookingVar?.industry, form: this.bookingVar?.form, mode: 'live', theme_slug: this.initServ.theme};
		if(this.initServ.theme){
			queryParams['mode']='preview';
		}
		// For only get the form section data
		if(data?.formLayout == 'multi_step'){
			queryParams['section'] = 'form';
		}
		if(this.initServ.firstPageData && this.initServ.firstPageSlug && this.apiServ.checkAPIRes(this.initServ.firstPageData) && this.initServ.firstPageData.data && this.bookingVar && this.bookingVar.industry == this.initServ.firstPageData?.data?.industry_id && this.bookingVar.form == this.initServ.firstPageData?.data?.form_id){
			setTimeout(() => {
				this.onResultCallback(JSON.parse(JSON.stringify(this.initServ.firstPageData)), data, JSON.parse(JSON.stringify(this.initServ.firstPageSlug)));
			}, 0)
		}else{
			this.apiServ.callApiWithPathQueryVars('GET', 'PageData', [0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, data, pageSlug));
		}
		// For only get the form section data
		if(data?.formLayout == 'multi_step'){
			queryParams['form_type'] = 'multi_step';
		}
		this.apiServ.callApiWithPathQueryVars('GET', 'PageStyle',[0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.styleServ.applyPageStyle(res,'booking_form','',null,(data?.formLayout == 'multi_step'), data?.isEmbed));
	}

}
