/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
// Services
import { RenderComponentServ, InitServ } from './index';
// Constants
import { EMBED_Youtube_VIDEO_LINK, IS_MOBILE, IS_TAB, VIMEO_VIDEO_LINK } from '../Constants';

@Injectable({
	providedIn: 'root'
})
export class SectionServ {
	// Variables
	private settings: any;
	private content: any;
	private formInputKeys: any;
	private formBtns: any;
	private pageSlug: string = '';
	private bkngForm: boolean = false;

	constructor(private rcServ: RenderComponentServ, private initServ: InitServ) { }

	/**
	 * Set the service data, page setting & content.
	 * @param sett
	 * @param content
	 */
	// eslint-disable-next-line max-params
	public setServData(sett: any, content: any, formInputKeys?: string[], formBtns?: string[], slug: string = '', bkngForm: boolean = false) {
		this.settings = sett;
		this.content = content;
		this.formInputKeys = (formInputKeys && (formInputKeys).length > 0) ? formInputKeys : [];
		this.formBtns = (formBtns && (formBtns).length > 0) ? formBtns : [];
		this.pageSlug = slug;
		this.bkngForm = bkngForm;
		// For only booking form shown mobile view in case of only mobile
		if(this.bkngForm){
			this.rcServ.statusType = IS_TAB ? 'mobile_status' : 'status';
		} else {
			this.rcServ.statusType = IS_MOBILE ? 'mobile_status' : 'status';
		}
	}

	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param index
	 * @returns index
	 */
	public trackByFnIndex(index: number): number {
		return index;
	}
	/**
	 * Check the element status for web and mobile both
	 * @param id : element id this.content
	 * @returns true/false
	 */
	public checkElementStatus(id: string): boolean {
		if (this.settings && this.settings[id] && (this.settings[id][this.rcServ.statusType] || this.bkngForm)) {
			return true;
		}
		return false;
	}
	/**
	 * Return the element content
	 * @param id : element id
	 * @returns content/null
	 */
	public getContent(id: string): string | null {
		if (id && this.checkElementStatus(id) && this.content && this.content[id]) {
			return this.content[id];
		}
		return '';
	}
	/**
	 * Retrun the element urls
	 * @param id : element id
	 * @returns urls/null
	 */
	public getMediaUrls(id: string): Array<string> | null {
		if (id && this.checkElementStatus(id) && this.settings && ((this.settings[id].urls && (this.settings[id].urls).length > 0) || (this.settings[id].thumbnails && (this.settings[id].thumbnails).length > 0))) {
			let urls: string[] = [];
			for (let url of this.settings[id].urls) {
				if (url) {
					url = this.initServ.imgBase + ((url && url.startsWith("/")) ? url : '/' + url);
					urls.push(url);
				}
			}
			if (urls && urls.length > 0) {
				return urls;
			}
		}
		return null;
	}
	/**
	 * Get the thumbnail with video data
	 * @param id: element id
	 * @returns object
	 */
	private getMediaUrlsWithVideo(id: any): any {
		if (id && this.checkElementStatus(id) && this.settings && ((this.settings[id].urls && (this.settings[id].urls).length > 0) || (this.settings[id].thumbnails && (this.settings[id].thumbnails).length > 0))) {
			let obj: any = {
				id: id
			}
			// Video thumbnail
			if (this.settings[id].thumbnails && (this.settings[id].thumbnails).length > 0) {
				for (let thumbnail of this.settings[id].thumbnails) {
					if (thumbnail) {
						if (!(/^http:\/\//.test(thumbnail)) && !(/^https:\/\//.test(thumbnail))) {
							thumbnail = this.initServ.imgBase + ((thumbnail && thumbnail.startsWith("/")) ? thumbnail : '/' + thumbnail);
						}
						obj['thumbnail'] = thumbnail;
					}
				}
			}
			// Video url
			let url: string = '';
			if (this.settings[id].urls && (this.settings[id].urls).length > 0) {
				url = this.settings[id].urls[0];
				obj['url'] = url;
				// Video type: youtube embed/Vimeo
				if (this.settings[id].media_type == 'youtube') {
					obj['url'] = EMBED_Youtube_VIDEO_LINK + url;
				} else {
					obj['url'] = VIMEO_VIDEO_LINK + url;
				}
			}
			return obj;
		}
		return null;
	}
	/**
	 * Build normal text type object
	 * @param key : element id
	 * @returns element object/null
	 */
	public buildText(id: string): Object | null {
		if (this.getContent(id)) {
			return {
				id: id,
				content: this.getContent(id)
			}
		}
		return null;
	}
	/**
	 * Build media object
	 * @param id : element id
	 * @returns element object/null
	 */
	public buildMedia(id: string): Object | null {
		if (this.getMediaUrls(id)) {
			return {
				id: id,
				urls: this.getMediaUrls(id)
			}
		}
		return null;
	}
	/**
	 * Build group object
	 * @param id : element id
	 * @returns element object/null
	 */
	public buildGroup(id: string): Object | null {
		if (id && this.checkElementStatus(id) && this.settings && this.settings[id].elements && (Object.keys(this.settings[id].elements)).length > 0) {
			let elements = this.settings[id].elements;
			let obj = this.getElements(id, elements);
			return obj;
		}
		// Only for thank you section
		if (this.pageSlug && this.pageSlug == 'thank_you') {
			return this.getContent(id);
		}

		return null;
	}

	/**
	 * Get settings of contract popup from invoice json
	 * @param id
	 * @returns
	 */
	public buildContractGrp(id: string): object | null{
		if (id && this.settings && this.settings[id]){
			let obj = Object(this.settings[id]);
			return obj;
		}
		return null;
	}

	public getDesignId(id: string): string | null {
		if (id && this.settings && this.settings[id] && this.settings[id].design_id) {
			return this.settings[id].design_id;
		}
		return '';
	}
	/**
	 * Build the multiple button structure.
	 * @param id : element id
	 * @returns button object
	 */
	public buildMultiButtons(id: string): Object | null {
		if (this.checkElementStatus(id) && this.settings && this.settings[id].buttons && (this.settings[id].buttons).length > 0) {
			let btnObj: any = {
				id: id,
				buttons: []
			}
			for (let btn of this.settings[id].buttons) {
				if (btn && this.getContent(btn)) {
					let obj = {
						id: btn,
						design_id: this.settings[btn].design_id,
						link_to: this.settings[btn].link_to,
						link_url: this.settings[btn].link_url,
						content: this.getContent(btn),
						open_in_new: this.settings[btn].open_in_new
					}
					btnObj['buttons'].push(obj);
				}
			}
			return btnObj;
		}
		return null;
	}
	/**
	 * Build the list items
	 * @param id: element id
	 * @returns object
	 */
	public buildList(id: string): any {
		if (this.settings && this.settings[id] && this.checkElementStatus(id) && this.settings[id].items && (this.settings[id].items).length > 0) {
			let listObj: any = {
				id: id,
				items: []
			}
			// All button design id
			if (this.settings[id]?.design_id) {
				listObj['btn_design_id'] = this.settings[id]?.design_id;
			}
			for (let item of this.settings[id].items) {
				if (item && this.checkElementStatus(item) && this.settings[item].elements && (Object.keys(this.settings[item].elements)).length != 0) {
					let elements = this.settings[item].elements;
					let obj = this.getElements(item, elements);
					listObj['items'].push(obj);
				}
			}
			return listObj;
		}
		return null;
	}
	/**
	 * Build the section data
	 * @param secId : section id
	 * @param section : section object like title,desc,media,list & button
	 * @returns section object
	 */
	public buildSectionFields(secId: string, section: any, sectionData: any): any {
		let secElem: any = null;
		if (sectionData && sectionData.sections) {
			secElem = sectionData.sections[secId];
		}
		if (secElem && this.settings && this.settings[secId] && (this.settings[secId][this.rcServ.statusType] || this.bkngForm)) {
			for (let key in section) {
				if (secElem[key]) {
					switch (key) {
						case 'give_us_call':
						case 'social':
						case 'invite_using':
						case 'reviews':
						case 'faq':
						case 'get_in_touch':
						case 'thank_you':
						case 'review':
						case 'referrals':
						case 'summary':
						case 'invite_by':
						case 'appointments':
						case 'billing':
						case 'address':
						case 'invoice':
						case 'feedback':
						case 'confirm_msg':
						case 'received_list':
						case 'sent_list':
						case 'share_review':
						case 'message':
						case 'select':
						case 'notifications':
						case 'inv_details':
						case 'sidebar':
						case 'payment_info':
						case 'contract':
						case 'location':
							section[key] = this.buildGroup(secElem[key]);
							break;
						case 'profile':
						case 'listing':
						case 'pagination':
							if (!this.settings[secElem[key]].elements) {
								section[key] = secElem[key];
							} else {
								section[key] = this.buildGroup(secElem[key]);
							}
							break;
						case 'media':
						case 'download_pdf':
							section[key] = this.buildMedia(secElem[key]);
							break;
						case 'video':
							section[key] = this.buildVideo(secElem[key])
							break;
						case 'button':
							section[key] = this.buildMultiButtons(secElem[key]);
							if (!section[key]) {
								section[key] = this.buildButton(secElem[key]);
							}
							break;
						case 'list':
						case 'gallery':
						case 'categories':
						case 'services':
							section[key] = this.buildList(secElem[key]);
							break;
						case 'form':
							section[key] = this.buildForm(secElem[key]);
							break;
						case 'review_card':
						case 'card':
							section[key] = secElem[key];
							break;
						case 'form_btn':
						case 'no_btn':
						case 'update_btn':
						case 'view_btn':
						case 'add_btn':
						case 'yes_btn':
						case 'all_upcoming_btn':
						case 'whole_schedule_btn':
						case 'this_only_btn':
						case 'cancel_all_btn':
						case 'postpone_btn':
						case 'cancel_btn':
						case 'authorize_btn':
						case 'add_authorize_btn':
						case 'redirect_link':
						case 'save_btn':
						case 'keep_btn':
						case 'charge_btn':
						case 'deactivate_btn':
						case 'make_default_btn':
						case 'submit_btn':
						case 'send_btn':
						case 'load_more_btn':
							section[key] = this.buildButton(secElem[key]);
							break;
						case 'contract_popup':
							section[key]= this.buildContractGrp(secElem[key]);
							break;
						default:
							section[key] = this.buildText(secElem[key]);
							break;
					}
				}
			}
		}
		return section;
	}
	/**
	 * Build the section elements array
	 * @param id
	 * @returns
	 */
	public buildEleArray(id: string) {
		if (id && this.checkElementStatus(id) && this.settings && this.settings[id].elements && (this.settings[id].elements).length > 0) {
			return this.settings[id].elements;
		}
		return null;
	}
	/**
	 * Return the elements object
	 * @param id : Element id
	 * @param elements : elements object
	 * @returns Object
	 */
	public getElements(id: string, elements: any): Object {
		let obj: any = {
			id: id
		}
		for (let key in elements) {
			if (elements[key] && this.checkElementStatus(elements[key])) {
				let keyId = key + '_id';
				obj[keyId] = elements[key];
				switch (key) {
					case 'media':
						// Check if its a gallery and there is no thumbnail image with video then add static thumbnail image based on there media type.
						if (this.settings[elements[key]].media_type && (this.settings[elements[key]].media_type == 'youtube' || this.settings[elements[key]].media_type == 'vimeo') && !(this.settings[elements[key]].thumbnails && (this.settings[elements[key]].thumbnails).length > 0)) {
							// eslint-disable-next-line max-depth
							if (this.settings[elements[key]].media_type == 'vimeo') {
								this.settings[elements[key]].thumbnails = ['https://cdn.cdnlogo.com/logos/v/96/vimeo.svg'];
							} else {
								this.settings[elements[key]].thumbnails = ['https://cdn.cdnlogo.com/logos/y/92/youtube.svg'];
							}
						}
						if (this.settings[elements[key]].thumbnails && (this.settings[elements[key]].thumbnails).length > 0) {
							obj[key] = this.getMediaUrlsWithVideo(elements[key]);
						} else {
							obj[key] = this.getMediaUrls(elements[key]);
						}
						break;
					case 'video':
						obj[key] = this.buildVideo(elements[key])
						break;
					case 'button':
					case 'list_button':
						obj[key] = this.buildMultiButtons(elements[key]);
						if (!obj[key]) {
							obj[key] = this.buildButton(elements[key]);
						}
						break;
					case 'list':
						obj[key] = this.buildList(elements[key]);
						break;
					case 'link':
						obj[key] = this.buildLink(elements[key])
						break;
					case 'form':
						obj[key] = this.buildForm(elements[key]);
						break;
					case 'gmail_button':
					case 'yahoo_button':
					case 'find_loc_btn':
					case 'choose_another_loc_btn':
					case 'view_more_loc_btn':
					case 'add_pkg_btn':
					case 'add_another_item_btn':
					case 'add_item_btn':
					case 'other_cont_btn':
					case 'login_btn':
					case 'other_btn':
					case 'coupon_apply_btn':
					case 'coupon_remove_btn':
					case 'gift_card_apply_btn':
					case 'gift_card_remove_btn':
					case 'referral_apply_btn':
					case 'referral_remove_btn':
					case 'update_btn':
					case 'cookie_accept':
					case 'first_available_btn':
					case 'view_all_btn':
					case 'edit_btn':
					case 'add_btn':
					case 'print_btn':
					case 'download_btn':
					case 'view_btn':
					case 'info_btn':
					case 'send_gift_btn':
					case 'add_tip_btn':
					case 'book_btn':
					case 'edit_review_btn':
					case 'cancel_btn':
					case 'resume_btn':
					case 'redirect_link':
					case 'clear_btn':
					case 'continue_btn':
					case 'edit_profile_btn':
					case 'password_btn':
					case 'add_bkng_btn':
					case 'send_gift_card_btn':
					case 'back_btn':
						obj[key] = this.buildButton(elements[key]);
						break;
					case 'twitter':
					case 'email':
					case 'phone':
					case 'chat':
					case 'select':
					case 'balance':
					case 'success_referrals':
					case 'total_credits':
					case 'total_invites':
					case 'title':
					case 'cards':
					case 'bkng_rcpt_card':
					case 'gift_rcpt_card':
					case 'feedback':
					case 'confirmation_msg':
					case 'table':
					case 'address':
					case 'zipcode':
					case 'declined':
					case 'upcoming':
					case 'completed':
					case 'cancelled':
					case 'billing_info':
					case 'partial_payment':
					case 'payment_logs':
					case 'form_group':
					case 'terms_and_condition':
					case 'verify_code':
					case 'logo':
					case 'bkng_table':
					case 'resend_code':
					case 'bkng_reminder':
					case 'feedback_email':
					case 'bkng_modified':
					case 'referral_accepted':
					case 'bkng_cancellation':
					case 'charged_fee':
					case 'bkng_sms_reminder':
					case 'sms_notifications':
					case 'create_invoice':
					case 'update_invoice':
					case 'sms_create_invoice':
					case 'sms_update_invoice':
					case 'same_address':
						obj[key] = this.buildGroup(elements[key]);
						if (!obj[key]) {
							obj[key] = this.getContent(elements[key]);
						}
						break;
					case 'users':
					case 'emails':
					case 'phone_no':
						obj[key] = this.buildEleArray(elements[key]);
						break;
					case 'signature':
						obj[key] =  this.buildSignature(elements[key]);
						break;
					case 'lead_form':
						obj[key] = { id: elements[key] }
						break;
					default:
						obj[key] = this.getContent(elements[key]);
						break;
				}
			}
		}
		return obj;
	}

	public buildSignature(id:string): any {
			let listObj: any = {
			id: id,
			items: []
		}
		for(let item of this.settings[id].items) {
			let elements = this.settings[item]?.elements;
			if(elements && Object.keys(elements).length !== 0) {
				let obj = this.getElements(item, elements);
				listObj.items.push(obj);
			}
		}
		return listObj;
	}

	/**
	 * Build the link type elements data
	 * @param id
	 * @returns
	 */
	public buildLink(id: string): Object | null {
		if (this.checkElementStatus(id) && this.settings) {
			let obj = {
				id: id,
				disable_on: this.settings[id].disable_on ? this.settings[id].disable_on : [],
				link_to: this.settings[id].link_to,
				link_url: this.settings[id].link_url ? this.settings[id].link_url : '/',
				open_in_new: this.settings[id].open_in_new,
				content: this.getContent(id)
			}
			return obj;
		}
		return null;
	}
	/**
	 * Build video data
	 * @param id: element id
	 * @returns object/null
	 */
	public buildVideo(id: string): object | null {
		if (id && this.checkElementStatus(id) && this.settings && ((this.settings[id].urls && (this.settings[id].urls).length > 0) || this.settings[id].video_id)) {
			let obj: any = {
				id: id
			}
			// Video thumbnail
			if (this.settings[id].thumbnail) {
				obj['thumbnail'] = this.initServ.imgBase + this.settings[id].thumbnail;
			}
			// Video url
			let url: string = '';
			if (this.settings[id].urls && (this.settings[id].urls).length > 0) {
				url = this.settings[id].urls[0];
				obj['url'] = url;
			} else if (this.settings[id].video_id) {
				url = this.settings[id].video_id;
				// Video type: youtube embed/Vimeo
				if (this.settings[id].video_type == 'youtube') {
					obj['url'] = EMBED_Youtube_VIDEO_LINK + url;
				} else {
					obj['url'] = VIMEO_VIDEO_LINK + url;
				}
			}
			return obj;
		}
		return null;
	}
	/**
	 * Build the form data
	 * @param id: element id
	 * @returns object/null
	 */
	public buildForm(id: string): object | null {

		if (id && this.checkElementStatus(id) && this.settings && this.settings[id].elements && (Object.keys(this.settings[id].elements)).length > 0) {
			let elements = this.settings[id].elements;
			let obj: any = {
				id: id
			}
			for (let key in elements) {
				if (elements[key] && this.checkElementStatus(elements[key])) {
					let keyId = key + '_id';
					obj[keyId] = elements[key];
					switch (key) {
						case 'first_name':
						case 'last_name':
						case 'email':
						case 'password':
						case 'new_password':
						case 'confirm_password':
						case 'current_password':
						case 'phone':
						case 'name':
						case 'notes':
						case 'message':
						case 'additional_msg':
						case 'address':
						case 'zip_code':
						case 'apt_no':
						case 'default':
						case 'feedback':
						case 'confirmation_msg':
						case 'tip':
						case 'credit_card':
						case 'add_card':
						case 'location':
						case 'saved_card':
						case 'bkngs_with_card':
						case 'bkngs_with_cash':
						case 'placeholder_group':
						case 'rating':
						case 'cancel_policy':
						case 'cancel_reason':
						case 'payment_info':
						case 'reason':
						case 'role':
						case 'phone_number':
						case this.formInputKeys.includes(key) && key:
							obj[key] = this.buildGroup(elements[key]);
							if(key == 'sender_email' && this.settings[elements[key]]?.elements?.email_errors){
								let exstingEmailObj: any = this.buildGroup(this.settings[elements[key]]?.elements?.email_errors);
								delete exstingEmailObj.id;
								obj[key] = {
									...obj[key],
									...exstingEmailObj
								}
							}
							break;
						case 'button':
						case 'claim_btn':
						case 'save_btn':
						case 'add_btn':
						case 'cancel_btn':
						case 'rate_btn':
						case 'postpone_service':
						case 'this_btn':
						case 'all_btn':
						case 'fb_btn':
						case 'twitter_btn':
						case 'send_btn':
						case 'no_btn':
						case 'yes_btn':
						case 'update_btn':
						case 'back_btn':
						case 'next_btn':
						case this.formBtns.includes(key) && key:
							obj[key] = this.buildButton(elements[key])
							break;
						default:
							obj[key] = this.getContent(elements[key])
							break;
					}
				}
			}
			return obj;
		}else{
			//
			if(this.settings[id]?.lead_form_id){
				return {
					id: id
				}
			}
		}
		return null;
	}
	/**
	 * Build the single button
	 * @param id : element id
	 * @returns
	 */
	public buildButton(id: string): Object | null {
		if (this.checkElementStatus(id) && this.settings) {
			// if(this.getContent(id)){
			let btnObj: any = {
				id: id,
				design_id: this.settings[id].design_id,
				content: this.getContent(id),
				link_to: this.settings[id].link_to && this.settings[id].link_to,
				link_url: this.settings[id].link_url && this.settings[id].link_url,
				open_in_new: this.settings[id].open_in_new
			}
			return btnObj;
			// }
		}
		return null;
	}
	/**
	 * Check element status depend on settings
	 * @param settings: settings
	 * @param id: element id
	 * @returns boolean
	 */
	public checkEleStatus(settings: any, id: string): boolean {
		if (settings && settings[id] && settings[id][this.rcServ.statusType] && this.checkDisplayOnStatus(settings, id)) {
			return true;
		}
		return false;
	}
	// eslint-disable-next-line complexity
	private checkDisplayOnStatus(sett: any, id: string): boolean {
		if (sett && sett[id] && sett[id].display_on) {
			if ((sett[id].display_on).length > 0) {
				if ((sett[id].display_on).includes('customer')) {
					return true;
				} else if ((sett[id].display_on).includes('customer_back_end') && this.initServ.userInfo) {
					return true;
				} else if ((sett[id].display_on).includes('customer_front_end') && !this.initServ.userInfo) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
		return true;
	}
	// ---------------------Migration functions-----------------------------
	/**
	 * Perform migration of "Form settings" to "Theme builder"
	 */
	public formSettMigCallback(secId: string, sett: any = {}): void {
		if (secId && sett) {
			// console.log('formSettMigCallback');
			let secElems: any = this.rcServ.pageData?.sections[secId];
			let summElems: any = this.rcServ.pageData?.sections[this.rcServ.pageData?.booking_summary];
			this.lengthMig(summElems?.length, sett);
			this.professionalsMig(summElems?.professionals);
			this.extraMig(summElems?.extras, sett);
			this.excludeMig(summElems?.excludes, sett);
			this.tbtMig(summElems?.total_before_tax, sett);
			// this.totalDisplayTypeMig(summElems, sett);
			this.servNotiMig(secElems, sett);
			this.custLayoutMig(secElems?.customer_details, sett);
			this.termsAndCondMig(secElems?.accept_tc, sett);
			this.howItWorksMig(secElems?.how_it_works, sett);
			// this.keyInfoMig(secElems?.key_info, sett);
			// this.specialNoteMig(secElems?.special_note, sett);
			if(secElems.header_summary && this.rcServ.pageData?.section_settings[secElems.header_summary] && this.rcServ.pageData?.section_settings[secElems.header_summary].elements['total_before_tax']){
				this.tbtMig(this.rcServ.pageData?.section_settings[secElems.header_summary].elements['total_before_tax'], sett);
			}
		}
	}
	/**
	 * Migrate "Do you want your customers to see how long the service will take during bookings?"
	 * @param id Id of element
	 */
	private lengthMig(id: string, sett: any): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'display_on')){
			if(sett?.enable_booking_time_for){
				this.rcServ.pageData.section_settings[id]['display_on'] = ['admin', 'provider'];
				let custValArr: Array<string> = ['both', 'customer_front_end', 'customer_back_end'];
				if (custValArr.includes(sett?.enable_booking_time_for)) {
					(this.rcServ.pageData.section_settings[id]['display_on']).push('customer');
				}
			}
		}
	}
	/**
	 * If display_on was not available in the data, then add the default value.
	 * @param id Id of element
	 */
	private professionalsMig(id: string): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'display_on')){
			this.rcServ.pageData.section_settings[id]['display_on'] = ['admin', 'customer'];
		}
	}
	/**
	 * Migrate "Would you like to display the price of each extra inside the summary box?"
	 * @param id Id of element
	 */
	private extraMig(id: string, sett: any): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'design_id')){
			if(sett?.display_extra_price_in_summary && sett?.display_extra_price_in_summary == 'yes'){
				this.rcServ.pageData.section_settings[id]['design_id'] = 'bk_extra_v2';
			} else {
				this.rcServ.pageData.section_settings[id]['design_id'] = 'bk_extra_v1';
			}
		}
	}
	/**
	 * Migrate "Would you like to display the price of each exclude inside the summary box?"
	 * @param id Id of element
	 */
	private excludeMig(id: string, sett: any): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'design_id')){
			if(sett?.display_exclude_price_in_summary && sett?.display_exclude_price_in_summary == 'yes'){
				this.rcServ.pageData.section_settings[id]['design_id'] = 'bk_exclude_v2';
			} else {
				this.rcServ.pageData.section_settings[id]['design_id'] = 'bk_exclude_v1';
			}
		}
	}
	/**
	 * Migrate "Do you want to enable the “Notify me if it takes more time” under the booking form?"
	 * @param id Id of element
	 */
	private servNotiMig(secElems: any, sett: any): void {
		let id: string = this.rcServ.pageData.section_settings[secElems.service].elements['notify_cust'];
		if (!id) {
			id = secElems.service + '_notify_cust';
			this.rcServ.pageData.section_settings[id] = {
				type: 'input',
				status: true,
				mobile_status: true
			}
			this.rcServ.pageData.content[id] = 'Notify me if the job requires more time to complete.';
			this.rcServ.pageData.section_settings[secElems.service].elements['notify_cust'] = id;

		}
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'display_on')){
			this.rcServ.pageData.section_settings[id]['display_on'] = ['admin', 'customer'];
			if(sett?.notify_for_more_job_time && sett?.notify_for_more_job_time != 'yes'){
				this.rcServ.pageData.section_settings[id]['status'] =  false;
				this.rcServ.pageData.section_settings[id]['mobile_status'] = false;
			}
		}
	}
	/**
	 * Migrate "Would you like to split the customer details into two sections as contact info and address?"
	 * Migrate "Would you like to split the address in multiple fields such as city, state and zip code or show them as a single address field?"
	 * @param id Id of element
	 */
	private custLayoutMig(id: string, sett: any): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'design_id')){
			let designId: string = '';
			if (sett?.split_customer_details && sett?.split_customer_details == 'yes') {
				if (sett?.split_customer_address && sett?.split_customer_address == 'yes') {
					designId = 'bk_customer_v4';
				} else {
					designId = 'bk_customer_v3';
				}
			} else {
				if (sett?.split_customer_address && sett?.split_customer_address == 'yes') {
					designId = 'bk_customer_v2';
				} else {
					designId = 'bk_customer_v1';
				}
			}
			this.rcServ.pageData.section_settings[id]['design_id'] = designId;
		}
	}
	/**
	 * Migrate "Do you want to enable the “Terms and Conditions” option under the booking form for your customers?"
	 * @param id Id of element
	 */
	private termsAndCondMig(id: string, sett: any): void {
		// Terms and conditions
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'display_on')){
			if(sett?.terms_conditions && sett?.terms_conditions == 'yes'){
				this.rcServ.pageData.section_settings[id]['display_on'] = ['customer'];
				this.rcServ.pageData.section_settings[id]['required_for'] = ['customer'];
			} else {
				this.rcServ.pageData.section_settings[id]['display_on'] = [];
				this.rcServ.pageData.section_settings[id]['required_for'] = [];
			}
			if(!this.objHasProp(this.rcServ.pageData.section_settings[id].elements, 'error_msg')){
				let errorMsgId: string = id+'1';
				this.rcServ.pageData.section_settings[id].elements = {
					error_msg: errorMsgId
				}
				this.rcServ.pageData.section_settings[errorMsgId] = {
					type: 'text',
					status: true,
					mobile_status: true
				}
				this.rcServ.pageData.content[errorMsgId] = 'Please accept the terms and conditions';
			}
		}
	}
	/**
	 * Migrate "Show "How it works" on a form?"
	 * @param id Id of element
	 */
	private howItWorksMig(id: string, sett: any): void {
		// if(!sett.enable_how_it_work){
		// 	this.rcServ.pageData.section_settings[id].status = false;
		// 	this.rcServ.pageData.section_settings[id].mobile_status = false;
		// }
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'urls')){
			this.rcServ.pageData.section_settings[id]['urls'] = []
			if (sett?.how_it_work_url) {
				(this.rcServ.pageData.section_settings[id]['urls']).push(sett?.how_it_work_url);
			}
		}
	}
	/**
	 * Migrate "How would you like to handle $0 total before tax? "
	 * @param id Id of element
	 */
	private tbtMig(id: string, sett: any): void {
		if(this.rcServ.pageData?.section_settings[id] && !this.objHasProp(this.rcServ.pageData.section_settings[id], 'total_before_tax_type')){
			if(sett?.total_before_tax_type){
				this.rcServ.pageData.section_settings[id]['total_before_tax_type'] = sett?.total_before_tax_type;
			} else {
				this.rcServ.pageData.section_settings[id]['total_before_tax_type'] = 'leave_it_as';
			}
		}
	}
	// private totalDisplayTypeMig(summElems: any, sett: any): void {
	// 	if(summElems){
	// 		if(sett.total_display_type){
	// 			switch (sett.total_display_type) {
	// 				case 'total':
	// 					this.rcServ.pageData.section_settings[summElems.total_before_tax]['status'] = false;
	// 					this.rcServ.pageData.section_settings[summElems.total_before_tax]['mobile_status'] = false;
	// 					break;
	// 				case 'total_before_tax':
	// 					this.rcServ.pageData.section_settings[summElems.total]['status'] = false;
	// 					this.rcServ.pageData.section_settings[summElems.total]['mobile_status'] = false;
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 		}
	// 	}
	// }
	/**
	 * Key message
	 * @param id Section id
	 */
	// private keyInfoMig(id: any, sett: any): void {
	// 	if(!sett.key_information){
	// 		this.rcServ.pageData.section_settings[id].status = false;
	// 		this.rcServ.pageData.section_settings[id].mobile_status = false;
	// 	}
	// }
	/**
	 * Special notes
	 * @param id Section id
	 */
	// private specialNoteMig(id: any, sett: any): void {
	// 	if(sett.special_notes && sett.special_notes == 'no'){
	// 		this.rcServ.pageData.section_settings[id].status = false;
	// 		this.rcServ.pageData.section_settings[id].mobile_status = false;
	// 	}
	// }
	public objHasProp(obj: any, val: string): boolean {
		return Object.prototype.hasOwnProperty.call(obj, val);
	}
}
