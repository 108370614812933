import { Injectable, Self } from '@angular/core';
import { takeUntil} from 'rxjs';
// External lib
import { ToastrService } from 'ngx-toastr';
import * as dayjs from 'dayjs';
// Services
import { ApiServ, InitServ, UtilServ, BkngFormServ, NgOnDestroy } from './index';

@Injectable({
	providedIn: 'root'
})
export class BkngFrmCmnFunServ {
	// private variables
	private admnStngs: any;

	constructor(private apiServ: ApiServ, private initServ: InitServ, private utilServ: UtilServ, private bkngFormServ: BkngFormServ, private toastr: ToastrService, @Self() private destroy: NgOnDestroy) {
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
	}

	/**
	 * Function to calculate the total without tax.
	 */
	/**
	 * Calculate the total without tax
	 * @param displayTotal: total amount
	 * @param displaySaleTax: sale Tax
	 * @param displayGiftCardAmount: gift card amount
	 * @param displayReferralDiscount: referral discount
	 * @returns amount
	 */
	public totalWithoutTax(displayTotal: any, displaySaleTax: any, displayGiftCardAmount: any, displayReferralDiscount: any): any{
		let tWTax: number = 0;
		let total: any = displayTotal ? displayTotal : 0;
		let salesTax: any = displaySaleTax ? displaySaleTax : 0;
		let giftCardAmount: any = displayGiftCardAmount ? displayGiftCardAmount : 0;
		let referralAmount: any = displayReferralDiscount ? displayReferralDiscount : 0;
		tWTax = this.utilServ.roundToTwo(total - salesTax);
		tWTax = this.utilServ.roundToTwo(tWTax - giftCardAmount);
		tWTax = this.utilServ.roundToTwo(tWTax - referralAmount);
		if(tWTax < 0){
			tWTax = 0;
		}
		return tWTax;
	}
	/**
	 * Function to create global object for pricing parameters.
	 * @param industryId : industry id
	 * @param formId : form id
	 * @param settings : settings obj
	 */
	createGlobalPricingParam(formId: number, settings: any): any{
		let settingsObj: any = {}
		if(settings && this.utilServ.checkArrLength(settings.pricing_parameters)){
			for(let pricingParam of settings.pricing_parameters){
				let obj : any = {}
				if(pricingParam.value && (pricingParam.value).length > 0){
					obj['id'] = pricingParam.id;
					obj['name'] = pricingParam.name;
					obj['cat_name'] = this.bkngFormServ.getPricingParamCatName(pricingParam?.name, settings);
					obj['value'] = {};
					for(let section of pricingParam.value){
						obj.value[section.id] = section;
					}
				}
				if(+formId == 1){
					settingsObj[pricingParam.id] = obj;
				}else{
					settingsObj[0] = obj;
				}
			}
		}
		return settingsObj
	}
	/**
	 * Function to make the array for dates and day for day discount.
	 * @param dayDiscounts
	 * @returns
	 */
	makeDayDiscountArray(dayDiscounts: any): any {
		let dateDiscountsArray = [];
		let dayDiscountsArray = [];
		if(dayDiscounts && (dayDiscounts).length > 0){
			for(let dayDiscount of dayDiscounts){
				if(dayDiscount.discount_for == 'date'){
					dateDiscountsArray[dayDiscount.date] = dayDiscount;
				}else{
					dayDiscountsArray[dayDiscount.day] = dayDiscount;
				}
			}
		}
		return {
			dateDiscountsArray : dateDiscountsArray,
			dayDiscountsArray : dayDiscountsArray
		};
	}
	/**
	 * Function to check if reschedule allowed for selected service category.
	 * @param industryId
	 * @param serviceId
	 * @returns
	 */
	checkServiceForResPermission(industryId: number, serviceId: number): boolean{
		if(this.admnStngs?.merchant_settings?.reschedule?.customer_reschedule_service_categories && (this.admnStngs.merchant_settings.reschedule.customer_reschedule_service_categories).length > 0){
			for(let industry of this.admnStngs.merchant_settings.reschedule.customer_reschedule_service_categories){
				if(industry.id == industryId){
					if((industry.services).includes(+serviceId)){
						return true;
					}
					break;
				}
			}
			return false;
		}
		return true;
	}
	/**
	 * Function to prevent the card hold for the given case:
	 * Disable the card hold settings and add a booking.
	 * Precharge/charge the booking.
	 * Enable the card hold setting.
	 * Reschedule the booking such that it falls under card hold condition.
	 * In this case, card hold is performed on already charged booking.
	 * @param prefilledData: prefilled data
	 * @returns boolean
	 */
	// eslint-disable-next-line complexity
	public chkBkngCharge(prefilledData: any): boolean {
		if(prefilledData && this.utilServ.checkArrLength(prefilledData.payment_log)){
			for(let pl of prefilledData.payment_log){
				if((pl.payment_type == 'booking' || pl.payment_type == 'booking_remaining') && (pl.status == 'paid' || (pl.status == 're-authorized' && this.reauthBkngChargeType(pl)))){
					return false;
				}
			}
		}
		return true;
	}
	/**
	 * Check the booking charge type in case of re-authentication
	 * @param pl: payment log
	 * @returns boolean
	 */
	private reauthBkngChargeType(pl: any): boolean{
		return (pl.charge_type == 'charge' || pl.charge_type == 'attempt-charge' || pl.charge_type == 'pre-charge') ? true : false;
	}
	/**
	 * Function to check pre autorized.
	 * @param prefilledData
	 * @returns
	 */
	chkPreAuthorized(prefilledData: any): boolean {
		if(prefilledData?.payment_log && (prefilledData.payment_log).length > 0){
			for(let pl of prefilledData.payment_log){
				if(pl.status == 'authorized'){
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Check the card hold authorization
	 * @param prefilledData: prefilled data
	 * @returns boolean
	 */
	public chkCardHoldReAuthorized(prefilledData: any): boolean {
		if(prefilledData?.payment_log && (prefilledData.payment_log).length > 0){
			for(let pl of prefilledData.payment_log){
				if(pl.status == 'authorized' || (pl.charge_type == 'hold' && pl.status == 're-authorized')){
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * check the card hold enable in case of 3DS also
	 * @param chs: card hold object
	 * @param BKFrm: booking form
	 * @param preAuthorized: pre authorized
	 * @returns boolean
	 */
	public isCardHold(chs: any, BKFrm: any, preAuthorized: any, prefilledStatus: any): boolean {
		if(this.initServ.threeDSecure){
			return this.carhHold(chs, BKFrm, prefilledStatus);
		} else if(this.carhHold(chs, BKFrm, prefilledStatus) && !preAuthorized){
			return true;
		}
		return false;
	}
	/**
	 * Card hold condition
	 * @param chs: card hold object
	 * @param BKFrm: booking form
	 * @returns boolean
	 */
	private carhHold(chs: any, BKFrm: any, prefilledStatus: any): boolean {
		if(chs.card_on_hold == 'yes' && chs.card_hold_for != 'automatic' && this.checkCardHoldOn(chs, prefilledStatus) && BKFrm.value.arrival_date < chs.cardholdMaxDate && BKFrm.value.payment_method != 'cash'){
			return true;
		}
		return false;
	}
	/**
	 * Checks the card hold status based on the prefill status and card hold condition.
	 * @param chs - The object containing the card hold status information.
	 * @param prefilledStatus - The prefill status value.
	 * @returns {boolean} - Returns true if the card hold condition does not match the expected status, otherwise false.
	 */
	private checkCardHoldOn(chs: any, prefilledStatus: any): boolean {
		// Determine the expected card hold condition based on prefill status
		let holdCondition: string = prefilledStatus == 9 ? 'reschedule' : 'booking';
		// Return true if the card hold status does not match the expected condition
		return chs.card_hold_on != holdCondition;
	}
	/**
	 *
	 * @returns Function to create empty object for wages.
	 */
	createWagesEmptyObj(): any{
		let obj : any = {
			id  					: null,
			members 				: [],
			provider_type  	: "",
			reimbursements 	: null,
			wages  : {
				base_pay 		: null,
				base_pay_unit 	: "amount"
			}
		};
		return obj
	}
	/**
	 * Function to return prefilled wages
	 * @param providerWage
	 * @returns
	 */
	prefilledProviderWages(providerWage: any): any {
		let provider : any = this.createWagesEmptyObj();
		provider.id = providerWage.provider_id;
		provider.provider_type = 'single';
		if(providerWage.reimbursements){
			provider.reimbursements = providerWage.reimbursements;
		}
		provider.wages.base_pay = +providerWage.base_pay;
		provider.wages.base_pay_unit = providerWage.base_pay_unit;
		return provider;
	}
	/**
	 * Function to prefilled the common params for forms.
	 */
	// eslint-disable-next-line complexity
	commonPrefilledPatch(prefilledData: any){
		let bookingNote = prefilledData.booking_note ? prefilledData.booking_note : null;
		let privateCustomerNote = prefilledData.private_customer_note ? prefilledData.private_customer_note : null;
		let noteFromProvider = prefilledData.note_from_provider ? prefilledData.note_from_provider : null;
		let providerNote = prefilledData.provider_note ? prefilledData.provider_note : null;
		let specialNotes = prefilledData.special_notes ? prefilledData.special_notes : null;
		let nonAvailableProvider = prefilledData.non_available_provider ? prefilledData.non_available_provider : null;
		let excludeRescheduleFee = prefilledData.exclude_reschedule_fee ? prefilledData.exclude_reschedule_fee : null;
		let excludeCanellationFee = prefilledData.exclude_cancellation_fee ? prefilledData.exclude_cancellation_fee : null;
		let excludeCancellationFeeAfterFirstBooking = prefilledData.exclude_cancellation_fee_after_first_booking ? prefilledData.exclude_cancellation_fee_after_first_booking : null;
		let excludeMinimum = prefilledData.exclude_minimum ? prefilledData.exclude_minimum : null;
		let value : any = {
			uid: prefilledData.uid,
			zipcode: prefilledData.zipcode,
			base_location_id: prefilledData.base_location_id,
			location_id: prefilledData.location_id,
			location_type: prefilledData.location_type,
			service_category: prefilledData.service_category,
			frequency: prefilledData.frequency,
			base_frequency_id: prefilledData.base_frequency_id,
			is_partial_cleaning: prefilledData.is_partial_cleaning,
			is_marketplace: prefilledData.is_marketplace ? prefilledData.is_marketplace : false,
			address_id: prefilledData.address_id,
			booking_tax_type: prefilledData.booking_tax_type,
			booking_tax_rate: prefilledData.booking_tax_rate,
			created_by: prefilledData.created_by ? prefilledData.created_by : null,
			frequency_discount_on_bookings: prefilledData.frequency_discount_on_bookings,
			frequency_repeat_slug: prefilledData.frequency_repeat_slug,
			ip_address: prefilledData.ip_address,
			occurrence: prefilledData.occurrence,
			service_fee: prefilledData.service_fee,
			notify_customer_for_time: prefilledData.notify_customer_for_time ? prefilledData.notify_customer_for_time : false,
			cancellation_fee : prefilledData.cancellation_fee,
			cancellation_reason : prefilledData.cancellation_reason,
			charge_onetime_cancellation : prefilledData.charge_onetime_cancellation,
			provider_category : prefilledData.provider_category,
			provider_type : prefilledData.provider_type,
			recurring_dates : prefilledData.recurring_dates,
			key_info : prefilledData.key_info,
			key_with_provider : prefilledData.key_with_provider,
			key_note : prefilledData.key_note,
			alert_charge_now : prefilledData.alert_charge_now,
			alert_charge_text : prefilledData.alert_charge_text,
			admin_email_reminder : prefilledData.admin_email_reminder,
			admin_email_reminder_date : prefilledData.admin_email_reminder_date,
			admin_email_reminder_day_before : prefilledData.admin_email_reminder_day_before,
			admin_email_reminder_hours_before : prefilledData.admin_email_reminder_hours_before,
			admin_email_reminder_message : prefilledData.admin_email_reminder_message,
			expedited_amount : prefilledData.expedited_amount,
			adjust_price : prefilledData.adjust_price,
			price_adjustment_note : prefilledData.price_adjustment_note,
			time_adjustment_note : prefilledData.time_adjustment_note,
			adjusted_price : prefilledData.adjusted_price,
			wages_on_adjusted : prefilledData.wages_on_adjusted,
			apply_provider_discount : (prefilledData.apply_provider_discount) ? prefilledData.apply_provider_discount : false,
			adjust_time : prefilledData.adjust_time,
			adjusted_time : prefilledData.adjusted_time,
			booking_note : bookingNote,
			private_customer_note : privateCustomerNote,
			note_from_provider : noteFromProvider,
			provider_note : providerNote,
			special_notes : specialNotes,
			non_available_provider : nonAvailableProvider,
			exclude_reschedule_fee : excludeRescheduleFee,
			exclude_cancellation_fee : excludeCanellationFee,
			exclude_cancellation_fee_after_first_booking : excludeCancellationFeeAfterFirstBooking,
			exclude_minimum : excludeMinimum
		}
		return value;
	}
	/**
	 * Function to blank the common params for forms.
	 */
	addressPrefilledPatch(prefilledData: any){
		let value : any = {
			zipcode: prefilledData.address.zipcode,
			address: prefilledData.address.address,
			city: prefilledData.address.city,
			state: prefilledData.address.state,
			apt: prefilledData.address.apt
		}
		return value;
	}
	/**
	 * Function to blank the common params for forms.
	 */
	customerPrefilledPatch(prefilledData: any){
		let value : any = {
			address : prefilledData.address.address,
			address_id : prefilledData.customer_info.address_id,
			apt : prefilledData.address.apt,
			city : prefilledData.address.city,
			customer_type : 'existing customer',
			customer_zipcode : prefilledData.address.zipcode,
			email_id : prefilledData.customer_info.email_id,
			first_name : prefilledData.customer_info.first_name,
			last_name : prefilledData.customer_info.last_name,
			phone_number : prefilledData.customer_info.phone_number,
			profile_pic : prefilledData.customer_info.photo_url,
			state : prefilledData.address.state
		}
		if(prefilledData.customer_info?.send_sms_notification){
			value['send_sms_notification']=prefilledData.customer_info?.send_sms_notification;
		}
		return value;
	}
	/* function to check minimum limits and return */
	/**
	 * function to check minimum limits and return.
	 * @param bookingData
	 * @returns
	 */
	// eslint-disable-next-line max-params, complexity
	chkMinimumLimit(bookingData: any, BKFrm: any, priceLocalVar: any, chkminLimitData: any): boolean{
		if(bookingData.customer.emails && (bookingData.customer.emails).length > 0){
			for(let email of bookingData.customer.emails){
				email.value = (email.value).toLowerCase();
			}
		}
		if(BKFrm.controls['exclude_minimum'].value){
			return true;
		}else{
			if(chkminLimitData?.selectedService.enable_minimums || chkminLimitData?.selectedService.enable_minimum_time){
				return this.compareForMinimum(BKFrm, priceLocalVar, chkminLimitData);
			}else{
				return true;
			}
		}
	}
	/**
	 * Compare for minimum limits
	 * @param BKFrm
	 * @param selectedService
	 * @param priceLocalVar
	 * @returns
	 */
	private compareForMinimum(BKFrm: any, priceLocalVar: any, chkminLimitData: any){
		let {selectedService} = chkminLimitData;
		let minObj = this.setMinimumAmountTime(selectedService);
		let minimumAmount = minObj.minimumAmount;
		let firstRecMinimumAmount = minObj.minimumAmount;
		let afterNthRecMinimumAmount = minObj.minimumAmount;
		let minimumTime = minObj.minimumTime;

		let recPriceMinLimit = selectedService?.check_min_price_on_rec ? true : false;
		let recTimeMinLimit = selectedService?.check_min_time_on_rec ? true : false;
		let nextNthLimit : boolean = false;
		let afterNthLimit : boolean = false;

		if(recPriceMinLimit){
			let recLabelVisObj = this.checkRecLabelVisibility(BKFrm, chkminLimitData, nextNthLimit, afterNthLimit);
			nextNthLimit = recLabelVisObj.nextNthLimit;
			afterNthLimit = recLabelVisObj.afterNthLimit;
		}

		let finalAmount = (+priceLocalVar.displayDiscountedAmount);
		let firstRecFinalAmount = (+priceLocalVar.firstRecAptDiscountedAmount);
		let afterRecFinalAmount = (+priceLocalVar.afterNthAptDiscountedAmount);

		if(BKFrm.controls['adjust_price'].value){
			finalAmount = (+BKFrm.controls['adjusted_price'].value);
			firstRecFinalAmount = (+BKFrm.controls['adjusted_price'].value);
		}
		if(selectedService?.check_min_price_on == 'final'){
			finalAmount = (+priceLocalVar.displayFinalAmount)
			firstRecFinalAmount = (+priceLocalVar.firstRecAptFinalAmount);
		}
		let finalTime =   +BKFrm.controls['length'].value;
		if(BKFrm.controls['adjust_time'].value){
			finalTime = (+BKFrm.controls['adjusted_time'].value);
		}
		/**call common func **/
		minimumAmount = this.saveBkngPart(BKFrm.value, selectedService, priceLocalVar.displayCouponDiscount);
		let recCouponDiscount = this.calcRecAptCouponAmount(BKFrm, priceLocalVar);
		firstRecMinimumAmount = this.saveBkngPart(BKFrm.value, selectedService, recCouponDiscount, true);
		afterNthRecMinimumAmount = this.saveBkngPart(BKFrm.value, selectedService, null, true);

		let data = {
			nextNthLimit : nextNthLimit,
			firstRecMinimumAmount : firstRecMinimumAmount,
			firstRecFinalAmount : firstRecFinalAmount,
			recPriceMinLimit : recPriceMinLimit,
			minimumTime : minimumTime,
			finalTime : finalTime,
			recTimeMinLimit : recTimeMinLimit,
			afterNthLimit : afterNthLimit,
			afterNthRecMinimumAmount : afterNthRecMinimumAmount,
			afterRecFinalAmount : afterRecFinalAmount,
			minimumAmount : minimumAmount,
			finalAmount : finalAmount,
			selectedService : selectedService
		}
		return this.compareForMinimumPart(data);
	}
	private compareForMinimumPart(data : any){
		let {nextNthLimit, firstRecMinimumAmount, firstRecFinalAmount, recPriceMinLimit, minimumTime, finalTime, recTimeMinLimit, afterNthLimit, afterNthRecMinimumAmount, afterRecFinalAmount, minimumAmount, finalAmount, selectedService} = data;
		let nextNthData = {
			nextNthLimit : nextNthLimit,
			firstRecMinimumAmount : firstRecMinimumAmount,
			firstRecFinalAmount : firstRecFinalAmount,
			recPriceMinLimit : recPriceMinLimit,
			minimumTime : minimumTime,
			finalTime : finalTime,
			recTimeMinLimit : recTimeMinLimit
		}
		let afterNthData = {
			afterNthLimit : afterNthLimit,
			afterNthRecMinimumAmount : afterNthRecMinimumAmount,
			afterRecFinalAmount : afterRecFinalAmount,
			recPriceMinLimit : recPriceMinLimit,
			minimumTime : minimumTime,
			finalTime : finalTime,
			recTimeMinLimit : recTimeMinLimit
		}
		if((minimumAmount <= finalAmount) && (minimumTime <= finalTime)){
			if(!recPriceMinLimit && !recTimeMinLimit){
				return true
			}else if(this.comapreNextNthMinimum(nextNthData)){
				if(this.compareAfterNthMinimum(afterNthData)){
					return true;
				}else{
					this.showToastrForMinimum(selectedService, {minimumAmount: afterNthRecMinimumAmount, minimumTime: minimumTime}, {finalAmount: afterRecFinalAmount, finalTime: finalTime});
					return false;
				}
			}else{
				this.showToastrForMinimum(selectedService, {minimumAmount: firstRecMinimumAmount, minimumTime: minimumTime}, {finalAmount: firstRecFinalAmount, finalTime: finalTime});
				return false;
			}
		}else{
			this.showToastrForMinimum(selectedService, {minimumAmount: minimumAmount, minimumTime: minimumTime}, {finalAmount: finalAmount, finalTime: finalTime});
			return false;
		}
	}
	/**
	 * Set minimum amount time from selected service
	 * @param selectedService
	 * @returns
	 */
	private setMinimumAmountTime(selectedService: any){
		let minimumAmount = 0;
		if(selectedService.enable_minimums){
			minimumAmount = selectedService.minimum_price ? (+selectedService.minimum_price) : 0;
		}
		let minimumTime = 0;
		if(selectedService.enable_minimum_time || !(Object.prototype.hasOwnProperty.call(selectedService, "enable_minimum_time"))){
			minimumTime = selectedService.minimum_time ? (+selectedService.minimum_time) : 0;
		}
		return{
			minimumAmount: minimumAmount,
			minimumTime: minimumTime
		}
	}
	/**
	 * Show error toast message for minimum limits
	 * @param selectedService
	 * @param minimumObj
	 * @param finalObj
	 */
	private showToastrForMinimum(selectedService: any, minimumObj: any, finalObj: any){
		if(!(minimumObj.minimumAmount <= finalObj.finalAmount) && selectedService.enable_minimum_notice){
			this.toastr.error(selectedService.minimum_notice_text);
		}else if(!(minimumObj.minimumTime <= finalObj.finalTime)){
			if(selectedService.enable_minimum_time_notice){
				this.toastr.error(selectedService.minimum_time_notice_text);
			}else if(!(Object.prototype.hasOwnProperty.call(selectedService, "enable_minimum_time")) && selectedService.enable_minimum_notice){
				this.toastr.error(selectedService.minimum_notice_text);
			}else{
				this.toastr.error('Minimum limits not matched');
			}
		}else{
			this.toastr.error('Minimum limits not matched');
		}
	}
	/**
	 * Calculate recurring booking's coupon discount
	 * @param BKFrm
	 * @param priceLocalVar
	 * @returns
	 */
	private calcRecAptCouponAmount(BKFrm: any, priceLocalVar: any){
		let couponDiscountValue = (BKFrm?.value.coupon.discount) ? (BKFrm?.value.coupon.discount) : 0;
		let recCouponDiscount = this.utilServ.roundToTwo(couponDiscountValue);
		// if coupon discount type is percentage
		if(BKFrm?.value.coupon.discount_type == 'percentage'){
			recCouponDiscount = this.utilServ.roundToTwo(((+couponDiscountValue)/100)*(+priceLocalVar?.baseAmountForRecDiscount));
			recCouponDiscount = +recCouponDiscount > 0 ? +recCouponDiscount : 0;
		}
		return +recCouponDiscount > 0 ? +recCouponDiscount : 0;
	}
	/**
	 * Compare minimum value with next nth recurring values
	 * @param nextNthData
	 * @returns
	 */
	private comapreNextNthMinimum(nextNthData: any){
		let {nextNthLimit, firstRecMinimumAmount, firstRecFinalAmount, recPriceMinLimit, minimumTime, finalTime, recTimeMinLimit} = nextNthData;
		if(!nextNthLimit || ((firstRecMinimumAmount <= firstRecFinalAmount || !recPriceMinLimit) && (minimumTime <= finalTime || !recTimeMinLimit))){
			return true;
		}
		return false;
	}
	/**
	 * Compare minimum value with after nth recurring values
	 * @param afterNthData
	 * @returns
	 */
	private compareAfterNthMinimum(afterNthData: any){
		let  {afterNthLimit, afterNthRecMinimumAmount, afterRecFinalAmount, recPriceMinLimit, minimumTime, finalTime, recTimeMinLimit } = afterNthData;
		if(!afterNthLimit || ((afterNthRecMinimumAmount <= afterRecFinalAmount || !recPriceMinLimit) && (minimumTime <= finalTime || !recTimeMinLimit))){
			return true;
		}
		return false;
	}
	/**
	 * Check which recurring label visible according to conditions
	 * @param BKFrm
	 * @param chkminLimitData
	 * @param nextNthLimit
	 * @param afterNthLimit
	 * @returns
	 */
	private checkRecLabelVisibility(BKFrm: any, chkminLimitData: any, nextNthLimit: boolean, afterNthLimit: boolean) {
		let recType = this.whichRecBookingsCheckForMinLimit(BKFrm, chkminLimitData);
		switch(recType) {
			case 'before-after-nth':
			{
				nextNthLimit = true;
				afterNthLimit = true;
				break;
			}
			case 'recurring':
			{
				nextNthLimit = true;
				afterNthLimit = false;
				break;
			}
			default:
			{
				nextNthLimit = false;
				afterNthLimit = false;
			}
		}
		return {
			nextNthLimit: nextNthLimit,
			afterNthLimit: afterNthLimit
		}
	}
	// eslint-disable-next-line complexity
	private whichRecBookingsCheckForMinLimit(BKFrm: any, chkminLimitData: any){
		let {bookingType, prefilledData, selectedFrequency } = chkminLimitData;
		if((bookingType == 'add' || bookingType == 'draft') || (prefilledData && prefilledData?.frequency != BKFrm?.value?.frequency) || (prefilledData?.status == 9)){
			if(selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'){
				return 'before-after-nth';
			}else{
				if(selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) ||  BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking')){
					return 'recurring';
				}
			}
		}
		return 'no-recurring';
	}
	/**
	 * Function is part of saveBooking func on all forms.
	 * @param formValues
	 * @param selectedServiceType
	 * @param displayCouponDiscount
	 * @returns
	 */
	saveBkngPart(formValues: any, selectedServiceType: any, displayCouponDiscount: any, recFlag: boolean = false): number{
		let minimumAmount = 0;
		if(selectedServiceType && selectedServiceType.enable_minimums){
			minimumAmount = +selectedServiceType.minimum_price;
		}
		if(formValues.referral_discount && !recFlag){
			if(minimumAmount > formValues.referral_discount){
				minimumAmount = (+minimumAmount) - (+formValues.referral_discount);
			}else{
				minimumAmount = 0;
			}
		}
		if(displayCouponDiscount){
			if(minimumAmount > displayCouponDiscount){
				minimumAmount = (+minimumAmount) - (+displayCouponDiscount);
			}else{
				minimumAmount = 0;
			}
		}
		return minimumAmount;
	}
	/**
	 *
	 * @param bookingData
	 * @returns
	 */
	// eslint-disable-next-line complexity
	checkIfRescFeeCharge(bookingData: any, prefilledData: any, BKFrm: any, selectedServiceType: any): boolean{
		/* code to add log for reschedule fee debugging */
		try{
			let logObj : any = {
				type: 'Before popup open',
				b_id: prefilledData._id,
				exclude_reschedule_fee: BKFrm.controls['exclude_reschedule_fee'].value,
				b_status : prefilledData.status,
				rescheduleFeeChargeLog: this.rescheduleFeeChargeLog(bookingData, prefilledData, selectedServiceType),
				booking_data:{
					old: {
						booking_date: prefilledData.booking_date,
						arrival_time: prefilledData.arrival_time,
						same_day_booking: (prefilledData.same_day_booking) ? prefilledData.same_day_booking : '',
						payment_log: (prefilledData.payment_log) ? prefilledData.payment_log : ''
					},
					new:{
						booking_date: bookingData.booking_date,
						arrival_time: bookingData.arrival_time,
						same_day_booking: (bookingData.same_day_booking) ? bookingData.same_day_booking : ''
					}
				},
				system_current_date_time : new Date().toLocaleString(),
				system_time_zone_offset : new Date().getTimezoneOffset()
			}
			if(this.admnStngs?.merchant_settings?.reschedule){
				let rescSettings = this.admnStngs.merchant_settings?.reschedule;
				let obj = {
					charge_multiple_fee: rescSettings?.charge_multiple_fee ? rescSettings?.charge_multiple_fee : '',
					charge_reschedule_fee_for: rescSettings?.charge_reschedule_fee_for ? rescSettings?.charge_reschedule_fee_for : '',
					charge_reschedule_fees: rescSettings?.charge_reschedule_fees ? rescSettings?.charge_reschedule_fees : '',
					exclude_same_day_reschedule_fees: rescSettings?.exclude_same_day_reschedule_fees ? rescSettings?.exclude_same_day_reschedule_fees : '',
					reschedule_fees: rescSettings?.reschedule_fees ? rescSettings?.reschedule_fees : '',
					reschedule_fees_unit: rescSettings?.reschedule_fees_unit ? rescSettings?.reschedule_fees_unit : '',
					reschedule_max_time: rescSettings?.reschedule_max_time ? rescSettings?.reschedule_max_time : '',
					reschedule_time_type: rescSettings?.reschedule_time_type ? rescSettings?.reschedule_time_type : ''
				}
				logObj['reschedule_settings'] = obj;
			}
			this.saveRescheduleFeeErrLog(logObj);
		}
		catch(err){
			// console.log('error while logging data for reschdule fee debug')
		}
		/* code to add log for reschedule fee debugging end here*/

		if(!BKFrm.controls['exclude_reschedule_fee'].value && this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.reschedule && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes' && prefilledData.status != 3 && prefilledData.status != 9 && this.checkCanChargeRescheduleFee(bookingData, prefilledData, selectedServiceType)){
			return true;
		}
		return false
	}
	/**
	* Hit API to save log on charge reschedule fee
	*/
	saveRescheduleFeeErrLog(logData: any){
		this.apiServ.callApi('POST', 'SaveRescFeeErrLog', logData).pipe(takeUntil(this.destroy)).subscribe();
	}
	/**
	 * Function for reschedule fee charge log.
	 * @param bkng
	 * @param oldBkngData
	 * @param serviceCat
	 * @returns
	 */
	// eslint-disable-next-line max-lines-per-function, complexity
	rescheduleFeeChargeLog(bkng: any, oldBkngData: any, serviceCat: any): any {
		let obj : any = {
			checkIsFeeChargeOnServ : {
				returnVal : this.checkIsFeeChargeOnServ(serviceCat),
				enable_reschedule_fees: (serviceCat.enable_reschedule_fees) ? serviceCat.enable_reschedule_fees : ''
			},
			canChargeRescheduleFee : {
				returnVal : this.canChargeRescheduleFee(bkng, oldBkngData)
			},
			isBkngUnderChargeTimeframe: {
				returnVal : this.isBkngUnderChargeTimeframe(oldBkngData)
			},
			isRescheduleFeeAlreadyCharged: {
				returnVal : this.isRescheduleFeeAlreadyCharged(oldBkngData)
			},
			getRescheduleFee : {
				returnVal : this.getRescheduleFee(oldBkngData, serviceCat)
			}
		};
		// canChargeRescheduleFee
		if(this.admnStngs?.merchant_settings?.reschedule?.charge_reschedule_fees && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes'){
			let chargeRescheduleFeeFor = this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fee_for;
			obj['canChargeRescheduleFee']['chargeRescheduleFeeFor'] = chargeRescheduleFeeFor;
			if(chargeRescheduleFeeFor && chargeRescheduleFeeFor.length > 0){
				if(chargeRescheduleFeeFor.includes('any')){
					obj['canChargeRescheduleFee']['includeAny'] = 'success';
					// return true;
				}else if(chargeRescheduleFeeFor.includes('time')){
					obj['canChargeRescheduleFee']['old_booking_date'] = oldBkngData.booking_date;
					obj['canChargeRescheduleFee']['new_booking_date'] = bkng.booking_date;
					obj['canChargeRescheduleFee']['old_booking_arrival_time'] = oldBkngData.arrival_time;
					obj['canChargeRescheduleFee']['new_booking_arrival_time'] = bkng.arrival_time;
					if((oldBkngData.booking_date != bkng.booking_date) || (oldBkngData.arrival_time != bkng.arrival_time)){
						obj['canChargeRescheduleFee']['timeChange'] = 'success';
						// return true;
					}
				}else if(chargeRescheduleFeeFor.includes('date')){
					obj['canChargeRescheduleFee']['old_booking_date'] = oldBkngData.booking_date;
					obj['canChargeRescheduleFee']['new_booking_date'] = bkng.booking_date;
					if(oldBkngData.booking_date != bkng.booking_date){
						obj['canChargeRescheduleFee']['dateChange'] = 'success';
						// return true;
					}
				}
			}
		}
		// isBkngUnderChargeTimeframe
		obj['isBkngUnderChargeTimeframe']['same_day_booking'] = (oldBkngData.same_day_booking) ? oldBkngData.same_day_booking : '';
		if(oldBkngData && oldBkngData.same_day_booking && this.admnStngs.merchant_settings?.reschedule?.exclude_same_day_reschedule_fees){
			// return false;
		}else{
			if( this.admnStngs && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes'){
				let time = Math.floor(Date.now()/1000);
				let rescheduleTime : any;
				let hours = Math.floor(+oldBkngData.arrival_time/100);
				let minutes = +oldBkngData.arrival_time%100;
				let bookingDate = this.utilServ.convertToTM(this.utilServ.dateObj(oldBkngData.booking_date, hours, minutes));
				if(this.admnStngs.merchant_settings?.reschedule?.reschedule_time_type && this.admnStngs.merchant_settings?.reschedule?.reschedule_time_type == 'day_before'){
					let dayBeforeRescheduleTimeHours = 0;
					let dayBeforeRescheduleTimeMinutes = 0;

					if(this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time && this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time > 0){
						dayBeforeRescheduleTimeHours = Math.floor(+this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time/100);
						dayBeforeRescheduleTimeMinutes = +this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time%100;
					}
					let serviceDate = this.utilServ.dateObj(oldBkngData.booking_date, 0, 0);
					serviceDate.setDate(serviceDate.getDate()-1);
					let year= serviceDate.getFullYear();
					let month= serviceDate.getMonth()+1;
					let day = serviceDate.getDate();
					let hours= dayBeforeRescheduleTimeHours;
					let minutes = dayBeforeRescheduleTimeMinutes;
					let seconds = 0;
					rescheduleTime = this.utilServ.convertToTM(month+'/'+(day)+'/'+year+' '+hours+':'+minutes+':'+seconds);
				}
				else{
					if(this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time){
						let hoursBeforeRescheduleTime = (+this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time)*3600;
						rescheduleTime = bookingDate - hoursBeforeRescheduleTime;
					}
				}
				obj['isBkngUnderChargeTimeframe']['rescheduleTime'] = rescheduleTime;
				obj['isBkngUnderChargeTimeframe']['time'] = time;
				if(rescheduleTime < time){
					// return true;
				}else{
					// return false;
				}
			}else{
				// return false;
			}
		}
		// isRescheduleFeeAlreadyCharged
		if(this.admnStngs?.merchant_settings?.reschedule){
			let chargeMultipleFee = (this.admnStngs.merchant_settings?.reschedule?.charge_multiple_fee) ? this.admnStngs.merchant_settings?.reschedule?.charge_multiple_fee : '';
			if(chargeMultipleFee){
				// return true;
			}else{
				obj['isRescheduleFeeAlreadyCharged']['payment_log'] = (oldBkngData.payment_log) ? oldBkngData.payment_log : '';
				if(oldBkngData.payment_log && (oldBkngData.payment_log).length > 0){
					let latesLogTime = 0;
					for(let log of oldBkngData.payment_log){
						if(log.payment_type == 'reschedule'){
							if(log.charged_on > latesLogTime){
								latesLogTime = log.charged_on;
							}
						}
					}
					obj['isRescheduleFeeAlreadyCharged']['latesLogTime'] = latesLogTime;
					let logNxtDate: any;
					if(latesLogTime && latesLogTime > 0){
						let d = new Date(latesLogTime*1000);
						let d1 = new Date(latesLogTime*1000);
						logNxtDate = Math.floor((d1.setDate(d.getDate()+1))/1000);
					}
					obj['isRescheduleFeeAlreadyCharged']['logNxtDate'] = logNxtDate;
					let currTime = Math.floor((new Date().getTime())/1000);
					obj['isRescheduleFeeAlreadyCharged']['currTime'] = currTime;
					// console.log('currTime', currTime, logNxtDate)
					if(currTime > logNxtDate){
						// return true;
					}else{
						// return false;
					}
				}
			}
		}
		// return true;
		return obj;
	}
	/**
	 * Check reschedule fee is enabled under selected service category.
	 * @param serviceCat
	 * @returns
	 */
	public checkIsFeeChargeOnServ(serviceCat: any): boolean {
		if(serviceCat){
			if(serviceCat.enable_reschedule_fees && serviceCat.enable_reschedule_fees == 'no'){
				return false;
			}
		}
		return true;
	}
	/**
	 * Check "charge_reschedule_fee_for" from store options and return response according to settings.
	 * @param bkng
	 * @param oldBkngData
	 * @returns
	 */
	// eslint-disable-next-line complexity
	public canChargeRescheduleFee(bkng: any, oldBkngData: any): boolean {
		if(this.admnStngs?.merchant_settings?.reschedule?.charge_reschedule_fees && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes'){
			let chargeRescheduleFeeFor = this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fee_for;
			if(chargeRescheduleFeeFor && chargeRescheduleFeeFor.length > 0){
				if(chargeRescheduleFeeFor.includes('any')){
					return true;
				}else if(chargeRescheduleFeeFor.includes('time')){
					if((oldBkngData.booking_date != bkng.booking_date) || (oldBkngData.arrival_time != bkng.arrival_time)){
						return true;
					}
				}else if(chargeRescheduleFeeFor.includes('date')){
					if(oldBkngData.booking_date != bkng.booking_date){
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Check reschedule time with settings
	 * @param oldBkngData
	 * @returns
	 */
	public isBkngUnderChargeTimeframe(oldBkngData: any): boolean {
		if(oldBkngData.same_day_booking && this.admnStngs.merchant_settings?.reschedule?.exclude_same_day_reschedule_fees){
			return false;
		}else{
			if(this.admnStngs && this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes'){
				let time = Math.floor(Date.now()/1000);
				let rescheduleTime : any;
				let hours = Math.floor(+oldBkngData.arrival_time/100);
				let minutes = +oldBkngData.arrival_time%100;
				let bookingDate = this.utilServ.convertToTM(this.utilServ.dateObj(oldBkngData.booking_date, hours, minutes));
				if(this.admnStngs.merchant_settings?.reschedule?.reschedule_time_type == 'day_before'){
					let dayBeforeRescheduleTimeHours = 0;
					let dayBeforeRescheduleTimeMinutes = 0;

					if(this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time > 0){
						dayBeforeRescheduleTimeHours = Math.floor(+this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time/100);
						dayBeforeRescheduleTimeMinutes = +this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time%100;
					}
					let serviceDate = this.utilServ.dateObj(oldBkngData.booking_date, 0, 0);
					serviceDate.setDate(serviceDate.getDate()-1);
					let year= serviceDate.getFullYear();
					let month= serviceDate.getMonth()+1;
					let day = serviceDate.getDate();
					let hours= dayBeforeRescheduleTimeHours;
					let minutes = dayBeforeRescheduleTimeMinutes;
					let seconds = 0;
					rescheduleTime = this.utilServ.convertToTM(month+'/'+(day)+'/'+year+' '+hours+':'+minutes+':'+seconds);
				}
				else{
					let hoursBeforeRescheduleTime = (+this.admnStngs.merchant_settings?.reschedule?.reschedule_max_time)*3600;
					rescheduleTime = bookingDate - hoursBeforeRescheduleTime;
				}
				if(rescheduleTime < time){
					return true;
				}else{
					return false;
				}
			}else{
				return false;
			}
		}
	}
	/**
	 * Check "charge_multiple_fee" value and compair from previous logs.
	 * @param oldBkngData
	 * @returns
	 */
	// eslint-disable-next-line complexity
	public isRescheduleFeeAlreadyCharged(oldBkngData: any): boolean {
		if(this.admnStngs?.merchant_settings && this.admnStngs.merchant_settings?.reschedule){
			let chargeMultipleFee = this.admnStngs.merchant_settings?.reschedule?.charge_multiple_fee;
			if(chargeMultipleFee){
				return true;
			}else{
				if(oldBkngData.payment_log && (oldBkngData.payment_log).length > 0){
					let latesLogTime = 0;
					let isRescLog = 0
					for(let log of oldBkngData.payment_log){
						// eslint-disable-next-line max-depth
						if(log.payment_type == 'reschedule'){
							// eslint-disable-next-line max-depth
							if(log.charged_on > latesLogTime){
								latesLogTime = log.charged_on;
								isRescLog++
							}
						}
					}
					if(isRescLog == 0){
						return true
					}
					let logNxtDate = 0;
					if(latesLogTime && latesLogTime > 0){
						let d = new Date(latesLogTime*1000);
						let d1 = new Date(latesLogTime*1000);
						logNxtDate = Math.floor((d1.setDate(d.getDate()+1))/1000);
					}
					let currTime = Math.floor((new Date().getTime())/1000);
					// console.log('currTime', currTime, logNxtDate,latesLogTime)
					if(currTime > logNxtDate){
						return true;
					}else{
						return false;
					}
				}
			}
		}
		return true;
	}
	/**
	 * Calculate reschedule fee
	 * @param oldBkngData
	 * @param serviceCat
	 * @returns
	 */
	public getRescheduleFee(oldBkngData: any, serviceCat: any): any{
		let chargeAmount;
		let chargeUnit;
		let amount;
		if(this.admnStngs.merchant_settings?.reschedule?.charge_reschedule_fees == 'yes'){
			chargeAmount = this.admnStngs.merchant_settings?.reschedule?.reschedule_fees;
			chargeUnit = this.admnStngs.merchant_settings?.reschedule?.reschedule_fees_unit;
		}
		if(serviceCat && serviceCat.enable_reschedule_fees != 'no' && serviceCat.reschedule_charge_type && !this.admnStngs.merchant_settings?.reschedule?.override_service_reschedule_fee){
			chargeAmount = serviceCat.reschedule_charge_value;
			chargeUnit = serviceCat.reschedule_charge_type;
		}
		if(chargeAmount){
			if(chargeUnit && chargeUnit == 'percentage'){
				amount = Math.round((((+oldBkngData.total)*(chargeAmount))/100) * 100) / 100;
			}else{
				amount = chargeAmount;
			}
		}else{
			amount = 0;
		}
		let obj = {
			chargeAmount : chargeAmount,
			chargeUnit : chargeUnit,
			amount : amount
		}
		return obj;
	}
	/**
	 * Check is reschedule fee need to be charged or not.
	 * @param bkng
	 * @param oldBkngData
	 * @param serviceCat
	 * @param isPostpone
	 * @returns
	 */
	public checkCanChargeRescheduleFee(bkng: any, oldBkngData: any, serviceCat: any, isPostpone = false){
		let canChargeRescFee = this.canChargeRescheduleFee(bkng, oldBkngData);
		if(isPostpone){
			canChargeRescFee = true;
		}
		let checkIsFeeChargeOnServ = this.checkIsFeeChargeOnServ(serviceCat)
		if(this.admnStngs.merchant_settings?.reschedule?.override_service_reschedule_fee){
			checkIsFeeChargeOnServ = true
		}
		if(checkIsFeeChargeOnServ && canChargeRescFee && this.isBkngUnderChargeTimeframe(oldBkngData) && this.isRescheduleFeeAlreadyCharged(oldBkngData) && this.getRescheduleFee(oldBkngData, serviceCat).amount > 0){
			return true;
		}else{
			return false;
		}
	}
	/**
	 * Function to create object for hold card api.
	 * @param cancelIntent
	 * @param formValues
	 * @returns
	 */
	holdCard(cancelIntent: any,formValues: any): any{
		let obj: any = {
			booking_date: formValues.booking_date,
			arrival_time: formValues.arrival_time,
			booking_id : formValues._id,
			base_location_id: formValues.base_location_id,
			industry_id: formValues.industry_id,
			frequency_id: formValues.frequency,
			customer_id: formValues.uid,
			email_id: formValues.customer.email_id,
			token: '',
			pay_with_cc: null,
			dataValue : '',
			dataDescriptor : '',
			final_amount: formValues.final_amount,
			booking_tax: formValues.booking_tax,
			cancel_intent: cancelIntent,
		}
		if(formValues.payment_method == "new_credit_card"){
			obj.token = formValues.credit_card.token;
			obj.dataValue = formValues.credit_card.dataValue;
			obj.dataDescriptor = formValues.credit_card.dataDescriptor;
			if(formValues.credit_card?.billing_address){
				obj['billing_address'] = formValues.credit_card?.billing_address;
			}
			if(formValues?.card_last4_digits){
				obj['card_last4_digits'] = formValues?.card_last4_digits;
			}
			// Device data in case for braintree
			if(formValues.credit_card?.device_data){
				obj['device_data'] = formValues.credit_card?.device_data;
			}
		} else if(formValues.pay_with_cc ){
			obj.pay_with_cc = formValues.pay_with_cc
		}
		// For square case verification token
		if(formValues.credit_card?.verification_token){
			obj['verification_token'] = formValues.credit_card.verification_token;
		}
		return obj;
	}
	/**
	 * Function to check the date for card hold api.
	 */
	checkCardHoldData(){
		let chs = {
			card_on_hold : 'no',
			card_hold_days : 6,
			card_hold_failure_action : 'notify_admin',
			cardholdMaxDate : 0,
			card_hold_for : 'both',
			card_hold_on : 'both'
		}
		if(this.admnStngs.merchant_settings?.payment_settings?.put_card_on_hold){
			chs.card_on_hold = this.admnStngs.merchant_settings?.payment_settings?.put_card_on_hold
			chs.card_hold_days = this.admnStngs.merchant_settings?.payment_settings?.card_hold_days
			if(this.admnStngs.merchant_settings?.payment_settings?.card_hold_at && this.admnStngs.merchant_settings?.payment_settings?.card_hold_at == 'same_day'){
				chs.card_hold_days = 1;
			}
			chs.card_hold_failure_action = this.admnStngs.merchant_settings?.payment_settings?.card_hold_failure_action;
			chs.card_hold_for = this.admnStngs.merchant_settings?.payment_settings?.enable_card_hold_for;
			chs.card_hold_on = this.admnStngs.merchant_settings?.payment_settings?.enable_card_hold_on;
			let tempdate = dayjs();
			chs.cardholdMaxDate = dayjs(tempdate.format('YYYY-MM-DD')).add(+chs.card_hold_days, 'days').valueOf();
		}
		return chs
	}
	/**
	 * Release card function
	 * @param formValues
	 */
	releaseCard(formValues: any){
		let obj = {
			booking_id : formValues._id,
			apply_to_all : formValues.apply_to_all
		}
		// this.apiServ.callApi('POST', 'ReleaseCard', obj).pipe(takeUntil(this.destroy)).subscribe();
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return this.apiServ.callApi('POST', 'ReleaseCard', obj).pipe(takeUntil(this.destroy)).toPromise().then(async (_res: any)=>{
			return true;
		});
	}

	/**
	 * Constructs the payload for booking with payment gateway details.
	 * @param bookingForm The form data containing booking information.
	 * @param paymentGatewayToken The token received from the payment gateway.
	 * @returns The constructed booking payload.
	 */
	public bkngPaymentPayload(bookingData: any, paymentGatewayToken: any): any {
		bookingData['pay_with_cc']=null;
		bookingData['credit_card']['token'] = paymentGatewayToken?.token;
		// Square varification token
		if(this.initServ.threeDSecure && paymentGatewayToken?.verification_token){
			bookingData['credit_card']['verification_token']=paymentGatewayToken?.verification_token
		}
		let last4 = paymentGatewayToken?.card_last4_digits ? paymentGatewayToken?.card_last4_digits : paymentGatewayToken?.last4;
		if(last4){
			bookingData['card_last4_digits'] = last4;
		}
		// Device data in case for braintree
		if(paymentGatewayToken?.device_data){
			bookingData['credit_card']['device_data'] = paymentGatewayToken?.device_data;
		}
		// Authdot
		if(paymentGatewayToken?.dataValue && paymentGatewayToken?.dataDescriptor){
			bookingData['credit_card']['dataValue'] = paymentGatewayToken.dataValue;
			bookingData['credit_card']['dataDescriptor'] = paymentGatewayToken.dataDescriptor;
		}
		bookingData = this.getPostalCode(bookingData, paymentGatewayToken);
		return bookingData;
	}
	// Work in case of square only
	private getPostalCode(bookingData: any, paymentGatewayToken: any): any {
		if(bookingData['credit_card']?.billing_address && paymentGatewayToken?.postalCode){
			bookingData['credit_card']['billing_address']['zipcode'] = paymentGatewayToken?.postalCode;
		}
		return bookingData;
	}

	/**
	 * Add user card
	 * @param data : send data
	 */
	public addCardApi(payload: any, uId: number): string {
		return this.apiServ.callApiWithPathVariables('POST', 'PublicUserAddCard', [uId], payload).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any)=>{
			return resp?.data;
		});
	}

	/**
	 * Get customer details from the API based on the customer user ID (uId).
	 * Makes a GET request to the 'CustomersDetails': profile, addresses and cards endpoint and returns customer data if the response is valid.
	 * If the response is invalid or data is missing, returns null.
	 * @param uId - The unique identifier of the customer
	 * @returns A promise that resolves to the customer details or null
	 */
	public getCustomerDetailsApi(uId: number): any {
		return this.apiServ.callApiWithPathQueryVars('GET', 'CustomersDetails', [uId], {all_locations:'yes'}).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any)=>{
			if(this.apiServ.checkAPIRes(resp) && resp?.data){
				return resp.data;
			}
			return null;
		});
	}
}
