/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-depth */
import { Component, OnInit, ViewEncapsulation, Self, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, ApiServ, InitServ, UtilServ, BkngFormServ, RenderComponentServ, SectionServ } from '../../Services';
import { AddBkngServ } from '../index';

@Component({
	selector: 'bk-add-booking-form',
	templateUrl: './AddBookingForm.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class AddBookingFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	pageSett: any;
	secSett: any;
	parentPageSett: any;
	formMetaData: any;
	formSett: any;
	// Section fields
	section: any = {
		media: null,
		card_charged_after: null,
		accept_save_booking_tc: null,
		multi_step_header: null,
		multi_sel_ind:null,
		multi_next_btn: null,
		multi_prev_btn: null,
		multi_step_one: null,
		multi_step_two: null,
		multi_step_three: null,
		multi_step_four: null,
		one_step_form: null,
		two_step_form: null,
		location: null,
		service: null,
		frequency: null,
		pricing_params: null,
		extras: null,
		packages: null,
		addons: null,
		area_params: null,
		customer_details: null,
		personal_details: null,
		address_details: null,
		email: null,
		tip_parking: null,
		key_info: null,
		special_note: null,
		discount: null,
		payment_info: null,
		accept_tc: null,
		cookie_content: null,
		save_booking_btn: null,
		how_it_works: null,
		summary: null,
		reviews: null,
		fb_coupon: null,
		faq: null,
		service_providers: null,
		save_step_one_btn: null,
		go_back_to_step_one_btn: null,
		header_summary: null,
		calendar: null,
	}
	mediaStatus: boolean = true;

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	appData: any = this.initServ.appData; // App data
	bookingVar: any;

	displayTab: any;
	tabVisibility: boolean = false;
	paramFormId: string | null;
	couponCode: any;
	couponData: any;
	industryBar: boolean = true;
	shortFormData: any;
	widgetFormData: any;
	formLayout: string = '';
	selectedIndustryId: any;
	selectedFormId: any;
	selectedCombination: any;
	industries: any = [];
	industryData: any;
	noOfIndustries: any = 0;
	showPrequalifier: boolean = true;
	tabStyle: any = null;

	multiIndustries: boolean = false;
	showMultiFormBlock: boolean = true;
	isMultiIndsFrm: boolean = true;
	multiStepFrmFirstStep : boolean = false;

	constructor(public rcServ: RenderComponentServ, public apiServ : ApiServ, @Self() private destroy: NgOnDestroy, public initServ: InitServ, private actRoute: ActivatedRoute, public utilServ: UtilServ, public bkngFormServ: BkngFormServ, public addBkngServ: AddBkngServ, public secServ: SectionServ, private router: Router, private cDRef: ChangeDetectorRef) {
		// Should reuse route
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// Booking variables which is set by booking form components
		// variables: loaderId, industrySlug, selectedIndustryId, selectedFormId, selectedCombination, containerId
		this.bookingVar = this.addBkngServ.bookingVar;
		// Tab
		this.displayTab = this.actRoute.snapshot.queryParamMap.get('tab');
		// Form id
		this.paramFormId = this.actRoute.snapshot.queryParamMap.get('formid');
		// Coupon code
		this.getCouponCode();
		// Industry bar
		this.industryBar = (this.actRoute.snapshot.queryParamMap.get('bar') && this.actRoute.snapshot.queryParamMap.get('bar') == 'false') ? false : true;
		// Load the payment gateway script
		if(this.initServ.paymentGateway){
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			// this.utilServ.removeStorage();
			this.utilServ.id = null;
		}
		// Short form data
		this.shortFormData = this.bkngFormServ.getShortFormData;
		// Form widgets
		this.widgetFormData = this.utilServ.widgetFormData;
		// Set the industry, form id and showMultiFormBlock
		this.selectedIndustryId = this.bookingVar.industry;
		this.selectedFormId =  this.bookingVar.form;
		this.showMultiFormBlock = this.paramFormId ? false : true;
		// Form layout
		this.formLayout = this.bkngFormServ.getFormLayout(this.selectedIndustryId, this.selectedFormId);
		// Form combination
		this.selectedCombination = this.bookingVar.combination;
		// Industries
		if(this.appData && this.appData.industries && (this.appData.industries).length > 0){
			this.noOfIndustries = 0;
			for(let industry of this.appData.industries){
				if(industry.status == 1 && this.utilServ.checkIndustryStatusForCust(industry)){
					this.noOfIndustries = this.noOfIndustries + 1;
					(this.industries).push(industry);
				}
			}
		}
		// Pre qualifier
		this.showPrequalifier = true;
		// Multi industries
		if(this.industries && (this.industries).length > 1){
			this.multiIndustries = true;
		} else {
			this.multiIndustries = false;
		}
		// Industry settings
		this.industryData = this.bkngFormServ.industryFormSett;
		this.industryFormSett();
		// TODO: Get from form setting api date: March-31-2025
		if(this.addBkngServ.singleFormSett){
			this.secServ.formSettMigCallback(this.secId, this.addBkngServ.singleFormSett);
		}
		// Build section data
		this.buildFormSections();
		this.initServ.setLogoData.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.buildHeaderSection();
			}
		})
	}
	/**
	 * Get the coupon code
	 * Coupon code exist then call the api to get the coupon data
	 */
	private getCouponCode(): void {
		let urlParams: any = new URLSearchParams(window.location.search);
		this.couponCode = urlParams.get('coupon');
		if(!this.couponCode){
			this.couponCode = this.actRoute.snapshot.queryParamMap.get("coupon");
		}
		// Embedded prefilled params
		if(this.utilServ.embedStatus){
			let embededPrefilledParams: any = this.utilServ.appLocalStorage('embeded_params', true);
			if(embededPrefilledParams && embededPrefilledParams.coupon){
				this.couponCode = embededPrefilledParams.coupon;
			}
		}
		// Coupon code exist then call the api to get the coupon data
		if(this.couponCode){
			this.couponCodeAvail();
		}
	}
	/**
	 * Coupon availability
	 */
	private couponCodeAvail(): void {
		// Set query parameters
		let queryParams: any = {
			code: this.couponCode,
			user_id: this.utilServ.userId() ? this.utilServ.userId() : 0,
			language: this.initServ.savedLng
		}
		// API call
		this.apiServ.callApiWithQueryParams('GET', 'Coupon', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * Industry form setting condition checker
	 */
	private industryFormSett(): void {
		// Selected form id is null, get the form id form industry form settings
		// And again build page
		if(!this.selectedFormId){
			this.section = null;
			let sett: any = this.addBkngServ.getCombinationForm();
			let obj: any = this.bookingVar;
			obj['form'] = sett.formId;
			obj['combination'] = this.selectedCombination;
			obj['formLayout'] = this.bkngFormServ.getFormLayout(sett.industry, sett.formId);
			this.addBkngServ.setBkngVariables = obj;
			this.addBkngServ.buildPage(obj);
		}
		// Multi step form
		if(!this.multiIndustries && (!((this.industryData && this.industryData.combined_combination  && (this.industryData.combined_combination).length > 0) || (this.industryData && this.industryData.single_combination  && (this.industryData.single_combination).length > 0)) || this.paramFormId)){
			this.isMultiIndsFrm = false;
			this.showMultiStepForm(true);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Show the multi step form
	 * Scroll to top animation
	 * @param status boolean
	 */
	public showMultiStepForm(status:boolean){
		window.scrollTo({top: 0, behavior: 'smooth'});
		if(this.utilServ.inIframe(this.utilServ.embedStatus) && this.formLayout == 'multi_step'){
			this.bkngFormServ.scrollParentToTop()
		}
		this.multiStepFrmFirstStep = status;
	}
	/**
	 * Check the tab visibility
	 * @returns
	 */
	private checkTabVisibility(): boolean {
		if(this.utilServ.embedStatus && !this.initServ.theme && this.displayTab && this.displayTab != 'undefined'){
			if(this.displayTab == 'true'){
				return true;
			}
			return false;
		} else {
			if(this.appData && this.appData.industries && (this.appData.industries).length > 0 && (this.parentPageSett && this.parentPageSett.enable_industry_tab == 'yes')){
				return true;
			}
			return false;
		}
	}
	/**
	 * Industry change
	 * Navigate with industry slug
	 * @param industry industry object
	 */
	public industryChange(industry: any): void {
		this.shortFormData = null;
		this.widgetFormData = null;
		this.bkngFormServ.resetShortFormData();
		this.utilServ.resetWidgetFormData();
		this.selectedFormId = null;
		this.showPrequalifier = true;
		this.selectedIndustryId = industry.id;
		this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow'], industry.industry_slug]);
	}
	/**
	 * Combination option change build the page again
	 * @param option combination option
	 * @param type group/single group: Combine a form and combination && single: Combine 2 forms
	 */
	public combineOptionChange(option: any, type:string = 'group'): void {
		this.section = null;
		this.shortFormData = null;
		this.widgetFormData = null;
		this.bkngFormServ.resetShortFormData();
		this.utilServ.resetWidgetFormData();
		// Set the form widgets
		this.resetFormWidgets();
		// Prequalifier
		this.showPrequalifier = true;
		if(type == 'group'){
			this.selectedCombination = null;
		}
		if(type == 'group' && option.combination_id) {
			if(this.industryData.single_combination && (this.industryData.single_combination).length > 0){
				for(let combination of this.industryData.single_combination){
					if(combination.id == option.combination_id){
						this.selectedCombination = combination;
						this.selectedFormId = this.selectedCombination.prequalifier_data[0].form_id;
						// Form layout
						this.formLayout = this.bkngFormServ.getFormLayout(this.selectedIndustryId, this.selectedFormId);
					}
				}
			}
		} else{
			this.selectedFormId = option.form_id;
			// Form layout
			this.formLayout = this.bkngFormServ.getFormLayout(this.selectedIndustryId, this.selectedFormId);
		}
		let obj: any = this.bookingVar;
		obj['form'] = this.selectedFormId;
		obj['combination'] = this.selectedCombination;
		obj['formLayout'] = this.bkngFormServ.getFormLayout(this.selectedIndustryId, this.selectedFormId);
		this.addBkngServ.setBkngVariables = obj;
		this.addBkngServ.buildPage(obj);
		this.cDRef.detectChanges();
	}
	/**
	 * Again set the form widgets
	 */
	private resetFormWidgets(): void {
		let queryParams: any = null;
		if(this.actRoute.snapshot.queryParamMap){
			let queryParamMap: any = this.actRoute.snapshot.queryParamMap;
			if(queryParamMap.params && (Object.keys(queryParamMap.params)).length > 0){
				queryParams = queryParamMap.params;
			}
		}
		if(queryParams && (Object.keys(queryParams)).length > 0){
			if(queryParams.referrer_source || queryParams.location || queryParams.f_name || queryParams.l_name || queryParams.email || queryParams.phone || queryParams.zipcode){
				let formWidgets: any = {
					referrer_source : queryParams.referrer_source,
					location_id : queryParams.location,
					first_name : queryParams.f_name,
					last_name : queryParams.l_name,
					email_id : queryParams.email ? queryParams.email : '',
					phone_number : queryParams.phone,
					zipcode : queryParams.zipcode ? (queryParams.zipcode).trim() : ''
				}
				// Set the form widgets
				this.utilServ.setWidgetFormData = formWidgets;
			}
		}
	}
	/**
	 * Checked the combination default check is case of group only
	 * Group: Combine a form and combination
	 * @param option combination option
	 * @returns
	 */
	public combineDefaultChecked(option: any): boolean | null {
		if(option.combination_id){
			if(this.industryData.single_combination && (this.industryData.single_combination).length > 0){
				for(let combination of this.industryData.single_combination){
					if(combination.id == option.combination_id){
						if((combination.form_ids).includes(+this.selectedFormId)){
							return true;
						}
					}
				}
			}
		} else if(+this.selectedFormId == +option.form_id){
			return true;
		}
		return null;
	}
	/**
	 * Build the form sections
	 */
	private buildFormSections(): void {
		if(this.secId && this.rcServ.pageData){
			this.parentPageSett = this.rcServ.pageData.settings;
			this.pageSett = this.rcServ.pageData.section_settings; // page settings
			this.secSett = this.pageSett[this.secId]; // section settings
			this.formSett = this.rcServ.pageData.form_settings; // Form settings
			this.formMetaData = this.rcServ.pageData.meta_data; // Form meta data
			this.bkngFormServ.formMetaData = this.rcServ.pageData.meta_data; // Form meta data
			// Tab visibility
			this.tabVisibility = this.checkTabVisibility();
			// tab visibility, in case of embed(original form) by builder
			if(this.bookingVar && this.bookingVar.isEmbed){
				this.tabVisibility = this.bookingVar.embedIndustryTab
			}
			// tab visibility, in case of embed(original form) by builder, using embed url in builder
			if(this.utilServ.embedStatus && this.initServ.theme && this.displayTab && this.displayTab != 'undefined'){
				if(this.displayTab == 'true'){
					this.tabVisibility = true;
				} else {
					this.tabVisibility = false;
				}
			}
			// Set the multi step header and footer status
			let headerOnMulti: boolean = false;
			let footerOnMulti: boolean = false;
			if(this.formSett && this.formLayout == 'multi_step'){
				if(this.formSett.hide_header_on_multi_step && this.formSett.hide_header_on_multi_step == 'no'){
					headerOnMulti = true;
				}
				if(this.formSett.hide_footer_on_multi_step && this.formSett.hide_footer_on_multi_step == 'no'){
					footerOnMulti = true;
				}
			}
			this.utilServ.setMultiStepHeaderFooter(headerOnMulti, footerOnMulti);
			// Set the section all settings under the section service
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content, [], [], '', true);
			this.buildSectionFields(this.rcServ.pageData);
			// Tab style
			this.tabStyle = this.bkngFormServ.tabStyle(this.parentPageSett);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Build the blog sections fields
	 */
	private buildSectionFields(secData:any): void{
		let secElem : any = null;
		if(secData.sections){
			secElem = secData.sections[this.secId];
		}
		if(secElem && this.secSett){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "media":
						case "fb_coupon":
							this.section[key] =  this.secServ.buildMedia(secElem[key]);
							// If fb coupon image is not available, check the status and assigned the id
							if(key == 'fb_coupon' && !this.section[key]){
								let fbCoupon: any = {
									id: this.secServ.checkEleStatus(this.pageSett, secElem[key]) ? secElem[key] : null
								}
								this.section[key] = fbCoupon;
							}
							break;
						case "multi_step_header":
							if(this.formLayout == 'multi_step'){
								let multiId: string = secElem[key];
								if(multiId && this.pageSett && this.pageSett[multiId].elements && (Object.keys(this.pageSett[multiId].elements)).length > 0){
									let elements = this.pageSett[multiId].elements;
									let obj:any = {
										id: multiId
									};
									for(let key in elements){
										if(elements[key]){
											// let keyId = key+'_id';
											switch (key) {
												case "logo":
													obj[key] = this.secServ.checkEleStatus(this.pageSett,elements[key]) && elements[key];
													// if(obj[key]){
													// 	obj[keyId] = elements[key];
													// }
													break;
											}
										}
									}
									this.section[key] =  obj;
								}
							}
							break;
						case "multi_sel_ind":
						case "multi_step_one":
						case "multi_step_two":
						case "multi_step_three":
						case "multi_step_four":
							if(this.formLayout == 'multi_step'){
								this.section[key] =  this.secServ.buildGroup(secElem[key]);
							}
							break;
						case "multi_next_btn":
						case "multi_prev_btn":
							if(this.formLayout == 'multi_step'){
								this.section[key] = this.secServ.buildButton(secElem[key]);
							}
							break;
						case "one_step_form":
						case "two_step_form":
							this.section[key] =  secElem[key];
							break;
						case 'save_booking_btn':
						case 'save_step_one_btn':
						case 'go_back_to_step_one_btn':
							this.section[key] = this.secServ.buildButton(secElem[key]);
							break
						case "card_charged_after":
						case "location":
						case "service":
						case "frequency":
						case "pricing_params":
						case "extras":
						case "packages":
						case "addons":
						case "area_params":
						case "address_details":
						case "tip_parking":
						case "key_info":
						case "special_note":
						case "discount":
						case "payment_info":
						case "reviews":
						case "faq":
						case "service_providers":
						case "summary":
						case "calendar":
							this.section[key] = this.secServ.buildGroup(secElem[key]);
							break;
						case "email":
							this.section[key] = this.secServ.buildGroup(secElem[key]);
							if(secData.section_settings[secElem[key]]?.elements?.email_errors){
								this.section[key]['email_errors'] = this.secServ.buildGroup(secData.section_settings[secElem[key]]?.elements?.email_errors);
							}
							break;
						case "customer_details":
						case "personal_details":
							this.section[key] = this.secServ.buildGroup(secElem[key]);
							if(secData.section_settings[secElem[key]]?.elements?.email_errors){
								this.section[key]['email_errors'] = this.secServ.buildGroup(secData.section_settings[secElem[key]]?.elements?.email_errors);
							}
							if(key == 'customer_details'){
								this.section[key]['design_id'] = this.secServ.getDesignId(secElem[key]);
							}
							break;
						case "accept_tc":
							this.section[key] = this.secServ.buildText(secElem[key]);
							if(secData.section_settings[secElem[key]].elements.error_msg){
								let errorMsgData: any = this.secServ.buildText(secData.section_settings[secElem[key]].elements.error_msg);
								this.section[key] = {
									...this.section[key],
									error_msg_id: errorMsgData?.id,
									error_msg_content: errorMsgData?.content
								}
							}
							break;
						case "cookie_content":
						case "how_it_works":
						case "accept_save_booking_tc":
							this.section[key] = this.secServ.buildText(secElem[key]);
							break;
						case "header_summary":
							let header: any = secData.section_settings[secElem[key]];
							if(header.elements && (Object.keys(header.elements)).length > 0){
								this.section[key] = this.utilServ.summaryBuild(secElem[key], header.elements, this.secSett, this.pageSett);
							}
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
							break;
					}
				}
			}
		}
		this.mediaStatus = this.secServ.checkEleStatus(this.pageSett, this.section?.media?.id);
		// Build summary
		if(secData.sections && secData?.booking_summary){
			let summarySecElem : any = null;
			let summaryId = secData?.booking_summary;
			summarySecElem = secData.sections[summaryId];
			this.section['summary'] = this.utilServ.summaryBuild(summaryId, summarySecElem, this.secSett, this.pageSett);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * On result callback method
	 * @param res api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.couponData = res.data;
		} else {
			this.couponData = null;
		}
		this.cDRef.detectChanges();
	}
	private buildHeaderSection() {
		let headerFooter: any = this.initServ.headerFooter;
		if(headerFooter && headerFooter.site_data){
			let secId: any = headerFooter.header_id;
			if(secId){
				let sett: any = headerFooter.site_data.section_settings;
				this.secServ.setServData(sett, headerFooter.site_data.content);
				let sections = headerFooter.site_data.sections;
				let secElem : any = null;
				if(sections){
					secElem = sections[secId];
				}
				if(secElem){
					let logoId: string = secElem['logo'];
					// Set the globally logo data
					if(sett && sett[logoId] && sett[logoId].elements && (Object.keys(sett[logoId].elements)).length > 0){
						let elements = sett[logoId].elements;
						// We forcefully update status of media because there is no option to hide/show logo media.
						if(elements.media && sett[elements.media]){
							sett[elements.media].status = true;
							sett[elements.media].mobile_status = true;
						}
						let obj = this.secServ.getElements(logoId, elements);
						this.utilServ.setSiteLogo = obj;
					}
				}
			}
		}
	}
}
