import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder, FormControl } from '@angular/forms';
// Services
import { NgOnDestroy, InitServ, UtilServ, PopupServ, SectionServ, BkngFormServ } from '../../../../Services';
import { AuthServ } from '../../../../Core/_services'; // Auth Service
// Constant
import { EMAIL_REG_EXP } from '../../../../Constants';
//Custom validator
import { CustomValidators } from '../../../../Global/GlobalDefault';
// Address component
import { AddressComponent } from '../../Address/Address.component';

@Component({
	selector: 'bk-customer-details',
	templateUrl: './CustomerDetails.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerDetailsComponent implements OnInit, OnChanges {

	// Variables
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	@Input() currentUser: any;
	@Input() isQuote : any;
	@Input() selectedFrequency: any;
	@Input() selectedServiceType: any;
	@Input() settings: any;
	@Input() bookingType: string = '';
	@Input() customerForm!: FormGroup
	@Input() prefilledData: any;
	@Input() locationLayout: any;
	@Input() section: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() locationType: any;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() zipcode: any;
	// TODO: Harleen any;
	@Input() customerDetails: any;
	@Output() loginExistingUser : EventEmitter<any> = new EventEmitter();
	@Output() zipCodeChange: EventEmitter<any> = new EventEmitter();
	@Output() custmrChng : EventEmitter<any> = new EventEmitter();
	@Output() custDetailsChng : EventEmitter<any> = new EventEmitter();
	// Address component child
	@ViewChild('addressComp', {static: false}) addressComp: AddressComponent | undefined;

	loaderId: string = 'bkng-cust-details';
	disableSecEmailAndPhone: string = 'no';
	primaryNoReq: string = 'no';
	splitDetails: boolean = false;
	disabledFirstLastName: boolean = true;
	isDisabled: any;
	primaryEmailExist: boolean = false;
	userInfo: any;
	contacts: any;
	secondaryUserInfo: any;
	sectionCustomerDetails: any;
	sectionAddr: any;
	cookieStatus: boolean = false;
	buildBlockBasedOnPropNames: any = ['currentUser', 'selectedLocation', 'zipcode', 'locationType', 'customerDetails'];
	emailExistVar: boolean = false;
	sendSmsNotif: boolean = true;
	phoneNotifStatus: boolean = false;


	constructor(
		public initServ: InitServ,
		private formBuilder: FormBuilder,
		private customValidators: CustomValidators,
		public utilServ: UtilServ,
		private cDRef: ChangeDetectorRef,
		private authServ: AuthServ,
		private popupServ: PopupServ,
		private secServ: SectionServ,
		private bkngFromServ: BkngFormServ
	) {
		let {disable_secondary_email_and_phone = null, primary_number_required = null} = this.initServ.appAdmnStngs?.merchant_settings?.bookings ?? {};
		// Disabled secondary email and phone number
		if(disable_secondary_email_and_phone){
			this.disableSecEmailAndPhone = disable_secondary_email_and_phone;
		}
		// Primary number required
		if(primary_number_required){
			this.primaryNoReq = primary_number_required;
		}
	}

	// convenience getter for easy access to form fields
	get customerGroup(): FormGroup {
		return this.customerForm.controls['customer'] as FormGroup;
	}
	get emails(): FormArray {
		return this.customerGroup.controls['emails'] as FormArray;
	}
	get phoneNumbers(): FormArray {
		return this.customerGroup.controls['phone_numbers'] as FormArray;
	}

	ngOnInit(): void {
		this.buildUserBlock();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes){
			return;
		}
		for(let propName in changes) {
			let change = changes[propName];
			if (this.isPropsUpdated(change, propName) && JSON.stringify(change.currentValue) != JSON.stringify(change.previousValue)) {
				this.buildUserBlock();
				break;
			}
		}
	}

	private isPropsUpdated(change: SimpleChange, propName: string): boolean {
		return !change.firstChange && this.buildBlockBasedOnPropNames.includes(propName);
	}

	/**
	 * Builds and initializes the user block section based on the current section data and location type.
	 * - Sets customer and address details based on location type.
	 * - Updates validators and field statuses.
	 * - Checks and sets SMS notification preference.
	 * - Handles design-specific flags for customer details.
	 * - Applies phone number validations and updates the customer form group.
	 * - Checks for disabled field statuses and triggers change detection.
	 */
	public buildUserBlock(): void {
		if(this.section){
			if(this.locationType == 'SA'){
				this.sectionCustomerDetails = this.section.customer_details;
				this.sectionAddr = this.section.address_details;
			} else {
				this.sectionCustomerDetails = this.section.personal_details;
				this.customerGroup.controls['customer_zipcode'].clearValidators();
				this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
			}
			this.setCookieStatus();
			this.setPhoneNoStatus();
		}
		if(this.customerGroup.controls['send_sms_notification']?.value){
			this.sendSmsNotif = this.customerGroup.controls['send_sms_notification'].value == 'yes';
		}
		if(this.section.customer_details.design_id == 'bk_customer_v3' || this.section.customer_details.design_id == 'bk_customer_v4'){
			this.splitDetails = true;
		}
		// App/update phone number validation
		this.addUpdatePhoneNoValidation();
		// Update customer group
		this.updateCustomerGroup();
		// Disabled fields status
		this.checkSmsNotif();
		this.isDisabled = this.disabledParamScope();
		this.cDRef.detectChanges();
	}

	/**
	 * Sets the cookie status to true if the form is a multi-step form and the cookies content is valid.
	 */
	private setCookieStatus(): void {
		if(this.isMultiStepForm && this.checkCookiesContent()){
			this.cookieStatus = true;
		}
	}

	/**
	 * Checks if the cookies content is valid based on the secondary customer details and section service.
	 * @returns {boolean} - Returns true if the secondary customer details contain cookie content and the element status is valid; otherwise, false.
	 */
	private checkCookiesContent(): boolean {
		return this.sectionCustomerDetails?.cookie_content && this.secServ.checkEleStatus(this.pageSett,this.sectionCustomerDetails?.cookie_content_id);
	}

	/**
	 * Sets the phone notification status based on the secondary customer details.
	 * If a phone notification ID is present in the secondary customer details,
	 * it checks the element status using the section service and updates the phone notification status.
	 */
	private setPhoneNoStatus(): void {
		if(this.sectionCustomerDetails?.phone_notification_id){
			this.phoneNotifStatus = this.secServ.checkEleStatus(this.pageSett, this.sectionCustomerDetails.phone_notification_id);
		}
	}

	/**
	 * Add/update phone number validation
	 */
	private addUpdatePhoneNoValidation(): void {
		if(this.primaryNoReq && this.primaryNoReq == 'yes'){
			this.customerGroup.controls['phone_number'].markAsUntouched();
			this.customerGroup.controls['phone_number'].setValidators([Validators.required]);
		} else{
			this.customerGroup.controls['phone_number'].markAsUntouched();
			this.customerGroup.controls['phone_number'].clearValidators();
		}
		this.customerGroup.controls['phone_number'].updateValueAndValidity();
	}

	/**
	 * Updated the customer group based on add/edit booking form
	 */
	private updateCustomerGroup(): void {
		if(this.bookingType == 'reschedule'){
			this.setUserProfile();
		} else {
			this.customerGroup.markAsUntouched();
			// this.customerGroup.controls['is_customer_new'].setValue(false);
			// Disabled first and last name
			this.disabledFirstLastName = this.customerForm.controls['uid'].value !== null && this.customerGroup.controls['first_name'].value ? true : false;
			// Get the user other contacts
			if(this.customerForm.controls['uid'].value){
				this.setUserProfile();
			} else {
				this.setCustomerSecondaryInfo(true);
			}
			// Existing customer
			this.updateExistingCustomer();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Sets the user profile details in the component state.
	 * - Extracts profile data from customer details.
	 * - Initializes contacts, secondary user info, emails, and phone numbers.
	 * - Triggers change detection after updating the values.
	 */
	private setUserProfile(): void {
		console.log(this.customerDetails, 'setUserProfile---------------');
		if(!this.customerDetails){
			return;
		}
		let { profile = null } = this.customerDetails;
		this.contacts = profile?.profiles;
		this.secondaryUserInfo = profile;
		if(this.secondaryUserInfo){
			this.setCustomerSecondaryInfo();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Initializes the customer security information controls (email and phone number)
	 * by extracting the first email and phone number from the customer form group values.
	 * If no values are present, defaults to null.
	 */
	private setCustomerSecondaryInfo(isControl: boolean = false): void {
		let customerSecondaryVals: any = isControl ? this.customerGroup.value : this.secondaryUserInfo;
		// Emails
		let secondaryEmail: any = (customerSecondaryVals?.emails)?.length > 0 ? customerSecondaryVals.emails[0] : null;
		this.createEmailsCtrl(secondaryEmail);
		// Phone numbers
		let secondaryPhone: any = (customerSecondaryVals?.phone_numbers)?.length > 0 ? customerSecondaryVals.phone_numbers[0] : null;
		this.createPhoneNumbersCtrl(secondaryPhone);
	}

	/**
	 * Creates and initializes the email form control inside the customer form group.
	 * Clears any existing controls, and if there are no emails present,
	 * adds a new email control populated with the provided security email values or defaults.
	 * @param secEmail - The security email object containing value, note, and send_notifications properties (optional).
	 */
	private createEmailsCtrl(secEmail: any = null): void {
		let emails: any = this.customerGroup.controls.emails as FormArray;
		emails.clear();
		if(this.customerGroup.controls['emails'].value && (this.customerGroup.controls['emails'].value).length == 0){
			emails.push(this.formBuilder.group({
				value: [((secEmail?.value) ? (secEmail.value) : ''), [Validators.pattern(EMAIL_REG_EXP)]],
				note: [((secEmail?.note) ? (secEmail.note) : '')],
				send_notifications: [(secEmail ? (secEmail.send_notifications) : true)]
			}));
		}
	}

	/**
	 * Creates and initializes the phone numbers form control inside the customer form group.
	 * Clears any existing controls, and if there are no phone numbers present,
	 * adds a new phone number control populated with the provided security phone values or defaults.
	 * @param secPhone - The security phone object containing value, note, and send_notifications properties (optional).
	 */
	private createPhoneNumbersCtrl(secPhone: any = null): void {
		let phoneNumbers: any = this.customerGroup.controls['phone_numbers'] as FormArray;
		phoneNumbers.clear();
		if(this.customerGroup.controls['phone_numbers'].value && (this.customerGroup.controls['phone_numbers'].value).length == 0){
			phoneNumbers.push(this.formBuilder.group({
				value: [((secPhone?.value) ? (secPhone.value) : '')],
				note: [((secPhone?.note) ? (secPhone.note) : '')],
				send_notifications: [(secPhone ? (secPhone.send_notifications) : this.utilServ.getSendSmsNotifDefVal(true))]
			}));
		}
	}

	/**
	 * Updates the customer group based on the existing customer selection.
	 * If the customer type is 'existing customer', it sets the first name, last name, phone number, and is_customer_new field.
	 * If the user info has send_sms_notification, it sets the send_sms_notification field.
	 * If the user info is a marketplace user, it sets the is_marketplace field.
	 */
	private updateExistingCustomer(): void {
		if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
			this.disabledFirstLastName = true;
			this.userInfo = this.initServ.userInfo;
			if(this.userInfo){
				this.customerGroup.patchValue({
					first_name : this.userInfo.first_name,
					last_name : this.userInfo.last_name,
					phone_number : this.userInfo.phone_number,
					is_customer_new: this.userInfo.is_new ? true : false
				});
				if(this.userInfo.send_sms_notification){
					this.customerGroup.controls['send_sms_notification'].setValue(this.userInfo.send_sms_notification);
					this.sendSmsNotif = this.userInfo.send_sms_notification == 'yes';
				}
				if(this.userInfo.is_marketplace){
					this.customerForm.controls['is_marketplace'].setValue(true);
				} else{
					this.customerForm.controls['is_marketplace'].setValue(false);
				}
			}
			return;
		}
		// if(this.customerGroup.controls['email_id'].value && !this.customerForm.controls['uid'].value && !this.isMultiStepForm){
		// Comment above line because we also need to run this code in case of multistep
		if(this.customerGroup.controls['email_id'].value && !this.customerForm.controls['uid'].value){
			this.emailExistCtrl(this.customerGroup.controls['email_id']);
			this.customerGroup.controls['email_id'].markAsTouched();
		}
	}

	/**
	 * Validates if the entered email already exists in the system.
	 * - Resets the `emailExistVar` flag to false initially.
	 * - Checks if the control value is valid (non-empty, passes basic validation).
	 * - If validating all emails and it's a secondary email, clears any existing errors.
	 * - Otherwise, performs async validation to check if the email exists in the database.
	 * - If it's a single email type:
	 *   - If the email exists, updates the password controls (optionally clearing the value).
	 *   - If it's a change operation and no error found, emits a customer change event.
	 * - Triggers change detection to update the view.
	 * @param control - The form control being validated.
	 * @param type - Validation type: 'single' or 'all'.
	 * @param isChange - Flag indicating if this is a change operation.
	 * @param emptyPwd - Determines whether to clear the password field if the email exists.
	 */
	public async emailExistCtrl(control: any, type: string='single', isChange: boolean = false, emptyPwd:boolean=true) {
		this.emailExistVar = false;
		if(!this.isCtrlValid(control)){
			return;
		}
		let email: any;
		if(type == 'all' && this.isSecondaryEmailOrPhones('emails', control)){
			control.setErrors(null);
		} else {
			email = await this.customValidators.emailExist(control.value, this.utilServ.userId());
			control.setErrors(email);
		}
		if(type == 'single'){
			if(email){
				this.emailExistVar = true;
				this.updatePwdCtrls(emptyPwd);
			}
			if(isChange && !email){
				this.custmrChng.emit();
			}
		}
		setTimeout(() => {
			this.cDRef.detectChanges();
		}, 0);
	}

	/**
	 * Checks if a form control is valid:
	 * - Control has a value.
	 * - Control does not have 'required' or 'pattern' errors.
	 * @param control - The form control to validate.
	 * @returns True if valid, false otherwise.
	 */
	private isCtrlValid(control: any): boolean {
		return (control.value && (!control.errors?.required && !control.errors?.pattern))
	}

	/**
	 * Checks if the given control's value matches the first phone number in the secondary user's phone numbers.
	 * @param control - The form control containing the phone number to be checked.
	 * @returns A boolean indicating whether the control's value matches the first phone number in the secondary user's phone numbers.
	 */
	private isSecondaryEmailOrPhones(type: string, control: any): boolean {
		let secondaryEmailOrPhones = this.secondaryUserInfo?.[type];
		return secondaryEmailOrPhones?.length > 0 && control.value == secondaryEmailOrPhones[0].value;
	}

	/**
	 * Updates the password control in the form.
	 * - If the control exists:
	 *    - Optionally resets its value and mark as untouched.
	 * - If it doesn't exist:
	 *    - Adds the control.
	 * @param emptyPwd - Whether to reset the password field to an empty value. Defaults to true.
	 */
	private updatePwdCtrls(emptyPwd:boolean=true): void {
		if(this.customerGroup.controls['password']){
			if(emptyPwd){
				this.customerGroup.controls['password'].setValue('');
				this.customerGroup.controls['password'].markAsUntouched();
			}
		} else {
			this.addPwdCtrl();
		}
	}

	/**
	 * Dynamically adds a 'password' control to the customer form group if it doesn't already exist.
	 * - Checks if the 'password' control is missing in the form group.
	 * - If missing, adds the control with a required validator.
	 * - Marks the newly added control as untouched to prevent immediate validation errors.
	 */
	public addPwdCtrl(): void {
		if(!this.customerGroup.controls['password']){
			this.customerGroup.addControl('password', new FormControl('', Validators.required));
			(this.customerGroup.controls['password'] as FormControl).markAsUntouched();
		}
	}

	/**
	 * Check the sms notification for existing customer and if disabled phone notification from builder
	 */
	private checkSmsNotif():void {
		let userInfo: any = this.initServ.userInfo;
		if(userInfo && !Object.prototype.hasOwnProperty.call(userInfo, 'send_sms_notification')){
			if(!this.phoneNotifStatus){
				this.customerGroup.controls['send_sms_notification'].setValue('');
			} else if(userInfo.sms_notification == false){
				this.customerGroup.controls['send_sms_notification'].setValue("no");
				this.sendSmsNotif = this.customerGroup.value['send_sms_notification'] == 'yes';
			}
		}
	}

	/**
	 * Determines whether certain form parameters should be disabled based on the booking type
	 * and customer type conditions.
	 * - If the booking type is 'reschedule' and the prefilled data status is in the list of completed/charged statuses, returns 'disabled'.
	 * - If the customer type is 'existing customer', returns 'disabled'.
	 * - Otherwise, returns null to indicate the parameters should remain enabled.
	 * @returns 'disabled' if the parameters should be disabled, otherwise null.
	 */
	public disabledParamScope(): string | null {
		if(this.bookingType == 'reschedule'){
			return (this.prefilledData && this.bkngFromServ.completedChargedStatuses.includes(this.prefilledData.status)) ? 'disabled' : null;
		} else {
			if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
				return 'disabled';
			}
			return null;
		}
	}

	/**
	 * Async validator to check if a phone number already exists in the system.
	 * - Validates the control first; if invalid, exits early.
	 * - If type is 'all' and the control corresponds to a secondary phone, clears any existing errors.
	 * - Otherwise, checks if the phone number exists via a custom validator.
	 * - Emits a customer details change event if there is no current user.
	 * - Triggers change detection to update the view.
	 * @param control - The form control containing the phone number to validate.
	 * @param type - Optional; specifies whether to validate all phone numbers ('all') or a single phone ('single' by default).
	 */
	public async phoneExistCtrl(control: any, type: string='single') {
		if(!this.isCtrlValid(control)){
			return;
		}
		let phone: any;
		if(type == 'all' && this.isSecondaryEmailOrPhones('phone_numbers', control)){
			control.setErrors(null);
		} else {
			phone = await this.customValidators.phoneExist(this.utilServ.phoneUnmask(control.value), this.utilServ.userId());
			control.setErrors(phone);
		}
		if(!this.currentUser){
			this.custDetailsChng.emit();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Emits login event if the password control is valid,
	 * otherwise marks the password field as touched to trigger validation errors.
	 */
	public loginUser(): void {
		if(this.customerGroup?.controls['password'].valid){
			this.loginExistingUser.emit();
		} else {
			this.customerGroup?.controls['password'].markAsTouched();
		}
	}

	/**
	 * Forgot password
	 */
	public forgotPwd(): void {
		let obj = { email_id: (this.customerGroup.controls['email_id'].value).toLowerCase()};
		this.authServ.passwordResetApi(true, obj, this.loaderId, false);
	}

	/**
	 * See other contact popup
	 */
	public otherContactsPopup(): void {
		let userId = this.customerForm.controls['uid'].value;
		this.popupServ.otherContactsPopup(userId, true);
	}

	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		this.cDRef.detectChanges();
	}

	/**
	 * Set the yes/no send sms notification field value
	 * @param event: HTML event
	 */
	public sendSmsNotifChange(event: Event): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		if(isChecked){
			this.customerGroup.controls['send_sms_notification'].setValue("yes");
		} else {
			this.customerGroup.controls['send_sms_notification'].setValue("no");
		}
	}
}
