import { Component, Input, OnInit, ViewEncapsulation, Self, SimpleChanges, ViewChild, ChangeDetectorRef, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs';
// External lib
import { ToastrService} from 'ngx-toastr';
// Interface
import { BillingAddress, BillingCard } from 'src/app/Interfaces';
// Services
import { ApiServ, BkngFrmCmnFunServ, InitServ, LoaderServ, NgOnDestroy, PaymentGatewayPayload, PaymentGatewayServ, UtilServ } from 'src/app/Services';
// Child component, Custom validator
import { PaymentGatewayComponent } from '../../GlobalDefault';


@Component({
	selector: 'bk-payment-info',
	templateUrl: './PaymentInfo.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PaymentInfoComponent implements OnInit, OnChanges {
	// Variables
	@Input() currentUser: any;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() paymentForm!: FormGroup;
	@Input() prefilledData: any;
	@Input() selectedServiceType: any;
	@Input() isMultiStepForm: any;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() locationLayout: any;
	@Input() cards: any;
	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component

	isCreditCard: boolean = false;
	isCash: boolean = false;
	isDisabled: any;
	cashVisibleOnService: boolean = false;
	loaderId: string = 'bkng-payment';
	buildPaymentForm: boolean = false;
	isSameAsBkngAddr : boolean = false;
	propNames: string[] = ['currentUser', 'selectedLocation', 'cards'];
	locCards: any = null;

	constructor(
		public utilServ: UtilServ,
		private apiServ: ApiServ,
		@Self() private destroy: NgOnDestroy,
		private loader: LoaderServ,
		private toasterServ: ToastrService,
		public initServ: InitServ,
		private cDRef: ChangeDetectorRef,
		private paymentServ: PaymentGatewayServ,
		public bkngCmnFun: BkngFrmCmnFunServ
	) {
		this.isCreditCard = this.utilServ.visiblePaymentMethod('credit_or_debit_card');
		this.isCash = this.utilServ.visiblePaymentMethod('cash');
	}

	ngOnInit(): void {
		this.buildPayment();
		/** function to call on changes of address every time **/
		this.addrGroup.controls['address'].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(() => {
			this.isSameAsBkngAddr = false;
		});
		// TODO: Anupam, remove this method after 2months goes to live. Add Bugsnag inside it.
		this.loadDefElemJson();
	}
	// convenience getter for easy access to form fields
	get customerGroup(): FormGroup{
		return this.paymentForm.controls['customer'] as FormGroup;
	}
	get addrGroup(): FormGroup{
		return this.paymentForm.controls['address'] as FormGroup;
	}
	get creditCardGroup(): FormGroup {
		return this.paymentForm.controls['credit_card'] as FormGroup;
	}
	// convenience getter for easy access to form fields
	get billingAddrGroup(): FormGroup{
		return this.creditCardGroup.controls['billing_address'] as FormGroup;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes){
			return;
		}
		for(let propName in changes) {
			let change = changes[propName];
			if (this.isPropsUpdated(change, propName) && JSON.stringify(change.currentValue) != JSON.stringify(change.previousValue)) {
				this.buildPayment();
				break;
			}
		}
	}

	private isPropsUpdated(change: SimpleChange, propName: string): boolean {
		return !change.firstChange && this.propNames.includes(propName)
	}

	/**
	 * Builds and initializes the payment form based on customer type,
	 * payment method preference (credit card or cash), and service category.
	 * - Determines appropriate payment method.
	 * - Initializes user cards if necessary.
	 * - Updates form state and handles UI changes (disable fields, show/hide options).
	 * - Shows loader during the operation to enhance UX.
	 */
	private buildPayment(): void {
		this.buildPaymentForm = false;
		this.loader.show(this.loaderId);

		let isExistingCustomer = this.customerGroup.controls['customer_type']?.value === 'existing customer';
		if (this.isAddOrDraft()) {
			if (isExistingCustomer && this.isCreditCard) {
				this.userCards();
			} else if (this.isCreditCard) {
				this.paymentForm.controls['payment_method'].setValue('new_credit_card');
				this.cardTypeChange('new_credit_card');
			} else if (this.isCash) {
				this.paymentForm.controls['payment_method'].setValue('cash');
				this.cardTypeChange('cash');
			}
		} else {
			this.userCards();
		}

		// Disabled sections
		this.isDisabled = this.disabledParamScope();
		// Cash visibility on service category
		this.cashVisibleOnService = this.cashVisibleForService();
		setTimeout(() => {
			this.buildPaymentForm = true;
			this.cDRef.detectChanges();
		}, 500);
		this.loader.hide(this.loaderId);
	}

	/**
	 * Checks if the current booking type is either 'add' or 'draft'.
	 * Useful for conditional flows that depend on initial or unsaved booking states.
	 * @returns {boolean} True if booking type is 'add' or 'draft'; otherwise, false.
	 */
	private isAddOrDraft(): boolean {
		return ['add', 'draft'].includes(this.bookingType);
	}

	/**
	 * Handles the logic for determining which credit card payment method to use
	 * based on customer type, available cards for the selected location, and
	 * the current booking state (add/draft/reschedule).
	 * - If in "add" or "draft" mode:
	 *   - Uses existing card if customer is existing and cards are available.
	 *   - Otherwise, defaults to using a new credit card.
	 * - If not in "add/draft" mode and paying with credit card with available cards:
	 *   - Triggers the onCardChange method.
	 * Also triggers change detection at the end to update the UI.
	 */
	private userCards(): void {
		let isExistingCustomer = this.customerGroup.controls['customer_type'].value === 'existing customer';
		let payWithCC = this.paymentForm.controls['pay_with_cc'].value;
		let baseLocId = this.paymentForm.controls['base_location_id'].value || 0;
		this.locCards = this.cards?.filter((location: any) => (location.location_id === baseLocId));
		/* If card is not exist for locations then return card with location id 0 */
		if(this.utilServ.checkArrLength(this.cards) && !this.utilServ.checkArrLength(this.locCards)){
			console.log('0 location If card is not exist for locations then return card with location id 0');
			this.locCards = this.cards?.filter((location: any) => (location.location_id === 0));
		}
		let hasCards = this.utilServ.checkArrLength(this.locCards);
		console.log(this.cards, 'this.cards------------------', baseLocId, '------', this.locCards, payWithCC);

		if (this.isAddOrDraft()) {
			let useExistingCard = hasCards && isExistingCustomer;
			let paymentMethod = useExistingCard ? 'existing_credit_card' : 'new_credit_card';

			this.paymentForm.controls['payment_method'].setValue(paymentMethod);
			this.cardTypeChange(paymentMethod);
		} else if (payWithCC && hasCards) {
			console.log('test reschedule case');
			this.onCardChange(null, payWithCC);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Handles changes to the selected card type in the payment form.
	 * - Resets the "Same as Booking Address" flag.
	 * - Removes billing address control if not using a new credit card.
	 * - If "existing credit card" is selected:
	 *    - Sets the default saved card details (card ID and last 4 digits).
	 * - If any other card type is selected:
	 *    - Clears the card details from the form.
	 * - Triggers change detection to update the view.
	 */
	public cardTypeChange(cardType: string): void {
		this.isSameAsBkngAddr = false;
		this.removeBillingAddr(cardType);
		if(cardType == 'existing_credit_card'){
			let billingCard: BillingCard = this.utilServ.getDefaultCardId(this.locCards);
			this.paymentForm.controls['pay_with_cc'].setValue(billingCard?.card_id);
			this.paymentForm.controls['card_last4_digits'].setValue(billingCard?.card_last4_digits);
		} else {
			this.paymentForm.controls['pay_with_cc'].setValue('');
			this.paymentForm.controls['card_last4_digits'].setValue('');
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Removes the billing address control from the credit card form group
	 * if the selected card type is not "new credit card".
	 */
	private removeBillingAddr(cardType: string): void {
		if(cardType !== 'new_credit_card' && this.creditCardGroup.controls['billing_address']){
			this.creditCardGroup.removeControl('billing_address');
		}
	}

	/**
	 * Handles the change event when a new card is selected.
	 * Updates the payment form with the selected card details.
	 * @param {Event} event - The change event containing the selected card ID.
	 */
	public onCardChange(event: Event|null = null, cardId: string|null = null): void {
		if(!cardId){
			cardId = event && (event.target as HTMLInputElement).value;
		}
		let billingCard: BillingCard = this.locCards.find((card: BillingCard) => card.id === cardId);
		if(!billingCard){
			billingCard = this.utilServ.getDefaultCardId(this.locCards);
		}
		this.paymentForm.controls['pay_with_cc'].setValue(billingCard?.id ?? billingCard?.card_id);
		this.paymentForm.controls['card_last4_digits'].setValue(billingCard?.last4 ?? billingCard?.card_last4_digits);
		this.cDRef.detectChanges();
	}

	/**
	 * Determines whether form parameters should be disabled based on the booking type
	 * and the status of prefilled data.
	 * - If booking type is "reschedule" and prefilled status is `2`:
	 *   - Returns 'disabled' to disable the input fields.
	 * - Otherwise:
	 *   - Returns null to keep the fields enabled.
	 * @returns {string | null} 'disabled' if conditions are met, otherwise null.
	 */
	public disabledParamScope(): string | null {
		if(this.bookingType == 'reschedule'){
			return this.prefilledData && (this.prefilledData.status == 2 ) ? 'disabled' : null;
		}
		return null;
	}

	/**
	 * Determines if the "Cash" payment option should be visible for the current service.
	 * Logic:
	 * - Always show for non-reschedule bookings.
	 * - Hide if payment is already completed (status 4).
	 * - Hide if service type is restricted (admin-only or customer can't edit) and payment method is card.
	 * - Hide if payment is in progress (status 1) and method is not cash.
	 * - Show in all other cases.
	 */
	private cashVisibleForService(): boolean {
		if (this.bookingType !== 'reschedule') {
			return true;
		}

		let { payment_method = '', status = 0} = this.prefilledData;
		const isCardPayment = payment_method === 'existing_credit_card' || payment_method === 'new_credit_card';

		if (status === 4) {
			return false;
		}

		if (this.isAdminOnlyServiceType() && isCardPayment) {
			return false;
		}

		if (status === 1 && payment_method !== 'cash') {
			return false;
		}

		return true;
	}

	/**
	 * Checks if the selected service type is restricted to admin or not editable by customer.
	 */
	private isAdminOnlyServiceType(): boolean {
		let { can_customer_edit = '', display_on = ''} = this.selectedServiceType;
		return (can_customer_edit === 'no' || display_on === 'admin');
	}

	/**
	 * Handles the update of payment details.
	 * - Shows the loader during the process.
	 * - If the credit card form is valid:
	 *    - If the selected payment method is "new credit card", generate a new payment token.
	 *    - Otherwise, create the required data object and update the declined payment.
	 * - If the credit card form is not valid and "new credit card" is selected:
	 *    - Update the child payment gateway component to show the Zipcode field.
	 *    - Refresh the child component to reflect changes.
	 * - Detect changes and hide the loader at the end of the process.
	 */
	public updatePayment(): void {
		this.loader.show(this.loaderId);
		if(this.creditCardGroup.valid){
			if(this.paymentForm.controls['payment_method'].value == 'new_credit_card'){
				this.generateToken();
			} else {
				let data: any = this.createReqdObj();
				this.updateDeclinePaymentApi(data);
			}
			return;
		}
		// Adjust behavior of payment gateway child component based on selected card type
		if(this.paymentForm.controls['payment_method'].value == 'new_credit_card' && this.paymentGatewayChild){
			this.paymentGatewayChild.isZipcode = true;
			this.paymentGatewayChild.refresh();
		}
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}

	/**
	 * Generates a payment token by interacting with the payment gateway.
	 * - Clears the currently selected credit card value to ensure fresh token generation.
	 * - Builds the payment data payload and requests the token from the payment service.
	 * - If token generation is successful:
	 *    - Sets token values in the form based on the payment gateway type.
	 *    - Prepares the final payload for backend processing.
	 *    - Initiates the update of declined payment with the prepared payload.
	 * - If token generation fails:
	 *    - Displays an error message to the user.
	 *    - Hides the loader to conclude the process.
	 */
	public async generateToken(): Promise<void>{
		this.paymentForm.controls['pay_with_cc'].setValue(null);
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(this.buildPaymentData(), this.paymentGatewayChild, true);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			if(this.initServ.paymentGateway == 'authorizedotnet'){
				this.creditCardGroup.controls['dataValue'].setValue(paymentGatewayToken.dataValue);
				this.creditCardGroup.controls['dataDescriptor'].setValue(paymentGatewayToken.dataDescriptor);
			} else {
				this.creditCardGroup.controls['token'].setValue(paymentGatewayToken?.token);
			}
			let payload = this.createReqdObj();
			payload = this.bkngCmnFun.bkngPaymentPayload(payload, paymentGatewayToken);
			this.updateDeclinePaymentApi(payload);
			return;
		}
		this.toasterServ.error('Please fill in the valid card details.');
		this.loader.hide()
	}

	/**
	 * Constructs the payment data object required for token generation.
	 * @returns Payment data including amount, location info, method, and billing address.
	 */
	private buildPaymentData(): {amount: number, location_id: number, base_location_id: number, payment_method: string, billing_address: BillingAddress}{
		let paymentValues: any = this.paymentForm?.value;
		return {
			amount: +paymentValues?.final_amount,
			location_id: +paymentValues?.base_location_id,
			base_location_id: +paymentValues?.base_location_id,
			payment_method: paymentValues?.payment_method,
			billing_address: this.creditCardGroup.controls['billing_address']?.value
		}
	}

	/**
	 * Constructs and returns the required payment request object based on form controls and prefilled data.
	 * - Collects values from paymentForm, creditCardGroup, and customerGroup form groups.
	 * - Includes credit card details and customer email.
	 * - Optionally adds the billing address if it's available in the credit card group.
	 * @returns {any} The constructed request object for payment processing.
	 */
	private createReqdObj(): any{
		let data: any = {
			_id : this.prefilledData._id,
			pay_with_cc : this.paymentForm.controls['pay_with_cc'].value,
			card_last4_digits: this.paymentForm.controls['card_last4_digits'].value,
			credit_card : {
				token : this.creditCardGroup.controls['token'].value,
				dataValue : this.creditCardGroup.controls['dataValue'].value,
				dataDescriptor : this.creditCardGroup.controls['dataDescriptor'].value
			},
			payment_method : this.paymentForm.controls['payment_method'].value,
			uid : this.paymentForm.controls['uid'].value,
			customer : {email_id : this.customerGroup.controls['email_id'].value},
			base_location_id : this.paymentForm.controls['base_location_id'].value,
			location_account_type  : this.creditCardGroup.controls['location_account_type'].value
		}
		if(this.creditCardGroup.controls['billing_address']){
			data['credit_card']['billing_address'] = this.creditCardGroup.controls['billing_address'].value;
		}
		return data;
	}

	/**
	 * Update declined card api
	 * @param payload
	 */
	private updateDeclinePaymentApi(payload: any): void {
		this.apiServ.callApiWithPathVariables('POST', 'UpdateDeclinedCard', [this.prefilledData._id], payload).pipe(takeUntil(this.destroy)).subscribe((resp:any)=>this.updateCardApiResp(resp));
	}

	/**
	 * Handles the API response for card update operation.
	 * Displays success or error messages, reloads the page on success,
	 * hides the loader, and triggers change detection.
	 * @param resp - API response object
	 */
	private updateCardApiResp(resp: any): void {
		if(this.apiServ.checkAPIRes(resp)){
			this.toasterServ.success(resp.message);
			setTimeout(() => {
				location.reload();
			}, 1500);
		} else if(resp?.message){
			this.toasterServ.error(resp.message);
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	/**
	 * Updates the billing address form group based on the provided value.
	 * If the value is truthy, copies values from the main address group to the billing address group.
	 * If falsy, resets the billing address fields to empty strings.
	 * @param value - Boolean flag indicating whether to copy the address or reset it
	 */
	public isSameAddrChange(value: any){
		let { address = '', zipcode = '', state = '', city = ''} = value ? this.addrGroup.value : {};
		this.billingAddrGroup.patchValue({
			address,
			zipcode,
			state,
			city
		})
	}

	/**
	 * Method set the default object for `Address` & `Same Address` when section field is null/undefined. This method will only be executed when the account is not migrated with billing address section.
	 */
	private loadDefElemJson(): void {
		if(!this.section?.address){
			this.section.address = this.utilServ.loadDefaultElemJson('address');
		}
		if(!this.section?.same_address){
			this.section.same_address = this.utilServ.loadDefaultElemJson('same_address');
		}
	}
}
