import { Component, Input, Output, OnInit, EventEmitter, ViewEncapsulation, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup} from '@angular/forms';
// Services
import { NgOnDestroy, UtilServ } from '../../../../Services';
@Component({
	selector: 'bk-location',
	templateUrl: './Location.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class LocationComponent implements OnInit, OnChanges {
	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() section: any;
	@Input() settings: any;
	@Input() bookingType: string = 'add'; // Param to define if its add or reschedule booking
	@Input() isDraft: boolean = false;
	@Input() locationForm!: FormGroup;
	@Input() prefilledData: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() pageSett: any;
	@Output() setLocation: EventEmitter<any> = new EventEmitter();

	selectedLocation: any;
	setLocationName: any;
	searchText: any;
	selectedType: string = 'both';
	defLocationId: number = 0;

	constructor(public utilServ: UtilServ, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {

		// Location selected type
		if(this.settings?.form_data?.preferred_service_location){
			switch(this.settings.form_data.preferred_service_location){
				case 'customer_location':
					this.selectedType = 'SA';
				break;
				case 'merchant_location':
					this.selectedType = 'ML';
				break;
				default:
					this.selectedType = 'both';
				break;
			}
		}
		// Booking type 'add' set the default location otherwise prefilled for used in reschedule
		this.prefillLoc();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes){
			return;
		}
		for(let propName in changes) {
			let change = changes[propName];
			if (!change.firstChange && JSON.stringify(change.currentValue) != JSON.stringify(change.previousValue)) {
				this.prefillLoc();
				break;
			}
		}
	}

	/**
	 * Prefilled location or set Default
	 */
	private prefillLoc(){
		if(['add'].includes(this.bookingType) && this.isLocationNotSelected()){
			this.setDefault();
		} else {
			this.setPrefilled();
		}
	}

	private isLocationNotSelected(): boolean {
		return !this.isDraft && !this.locationForm.controls['location_id'].value;
	}

	/**
	 * Set the default location
	 */
	private setDefault(): void {
		let defaultLoc: any;
		if(this.utilServ.checkArrLength(this.settings?.locations)){
			this.defLocationId = this.settings?.default_location_id;
			if(this.defLocationId){
				defaultLoc = this.defaultLocation(this.defLocationId);
			}
			if(!defaultLoc){
				defaultLoc = this.zeroIndexLocation()
			}
			// Set the required variables according to location
			if(defaultLoc){
				this.locSelect(defaultLoc);
			}
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Set the zeroth index value as selected location
	 * @returns
	 */
	private zeroIndexLocation(){
		return this.selectedType == 'both' ? this.settings?.locations[0] : this.defaultLocation();
	}

	/**
	 * Get the default location if location type is SA/ML any one
	 * @returns location
	 * Note: Intentionally used any
	 */
	public defaultLocation(defLocId: number = 0): any{
		let defaultLoc: any = null;
		for(let location of this.settings.locations){
			let isLocOfSelectedType: boolean = this.isLocTypeSelected(location);
			let flag: boolean = defLocId ? ((location?.location_id === defLocId) && (isLocOfSelectedType)) : (isLocOfSelectedType);
			if(flag){
				return location;
			}
		}
		return defaultLoc;
	}

	/**
	 * This function is used to determine if a location's type matches the selected type.
	 * The selected type can be either 'both', 'SA' (Service Area), or 'ML' (Merchant Location).
	 * If the selected type is 'both', the function will always return true.
	 * If the selected type is 'SA' or 'ML', the function will compare the location's type with the selected type.
	 * @param location - The location object to check.
	 * @returns A boolean indicating whether the location type matches the selected type.
	 */
	private isLocTypeSelected(location: any): boolean {
		return (this.selectedType === 'both' || (location?.location?.location_type === this.selectedType));
	}

	/**
	 * Set the selected location
	 */
	private setPrefilled(): void {
		if(this.settings.locations && (this.settings.locations).length > 0){
			for(let location of this.settings.locations){
				if(location._id == this.locationForm.controls['location_id'].value){
					this.selectedLocation = location._id;
					this.setLocationName = location.location.location_name;
					break;
				}
			}
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Location change
	 * @param location : selected location
	 */
	public locSelect(location: any): void {
		this.selectedLocation = location._id;
		this.setLocationName = location.location.location_name;
		// Set the required variables according to location
		this.setLocation.emit(location);
	}

	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy,
	 * Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param _index
	 * @param data
	 * @returns data id
	 */
	public trackById(_index:any, data: any) {
		return data?._id;
	}
}
