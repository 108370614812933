<ng-container *ngIf="settings && settings.form_data && settings.form_data.mode && settings.form_data.status; else underMaintenance">
	<form class="bk-form form-wrapper row pt-30 pb-50" [formGroup] = "BKFrm">
		<div class="col-12 col-md-12 col-lg-8 mb-lg-0 mb-30">
			<div [ngClass]="{'d-none': isStepOneValid}">
				<div [id]="section?.one_step_form" *ngIf="twoStepFormSections && twoStepFormSections.step_one && (twoStepFormSections.step_one).length > 0" class="tjs-card card border rounded-5 tjs-rounded--lg mb-30 bk-card">
					<div class="card-body py-0 d-flex flex-column">
						<!--Location-->
						<div class="position-relative" *ngIf="locationLayout != 'no_location'" [style.order]="sectionOrder.location">
							<!-- we used the buildAddressStatus sectin to build location section,
						   because this section dependent on prefilled data load in case of quote,
							so to solve this section, we used this status.-->
							<ng-container *ngIf="buildAddressStatus">
								<bk-location *ngIf="locationLayout == 'name_based'; else zipcode"
									[isQuoteEditable]="isQuoteEditable"
									[bookingType]="bookingType"
									[section]="section?.location"
									[pageSett]="pageSett"
									[locationForm]="BKFrm"
									[settings]="settings"
									(setLocation)="setLocation($event)">
								</bk-location>
								<!-- Zipcode based -->
								<ng-template #zipcode>
									<bk-zipcode #locationComp
										[isQuoteEditable]="isQuoteEditable"
										[prefilledData]="prefilledData"
										[bookingType]="bookingType"
										[section]="section?.location"
										[zipcodeForm]="BKFrm"
										[pageSett]="pageSett"
										[settings]="settings"
										[refreshLocComp]="refreshLocComp"
										(locationChange)="resetFormControl('location')"
										(locationFocusOut)="checkZipcodeAval($event)"
										(merchantLocFocusOut)="checkMerchantZipcodeAval($event)">
									</bk-zipcode>
								</ng-template>
							</ng-container>
							<!-- Loader -->
							<bk-app-loader [loaderId]="loaderIds?.location"></bk-app-loader>
						</div>
						<!--Service Category-->
						<bk-service-category [style.order]="sectionOrder.service"
							[isQuoteEditable]="isQuoteEditable"
							[bookingType]="bookingType"
							[section]="section?.service"
							[pageSett]="pageSett"
							[serviceForm]="BKFrm"
							[selectedLocation]="selectedLocation"
							[selectedServiceType]="selectedServiceType"
							[selectedFrequency]="selectedFrequency"
							[settings]="settings"
							[availableSett]="availableSett"
							[prefilledData]="prefilledData"
							(serviceChange)="serviceCatChange($event, true)"
							(hourlyServiceChange)="resetFormControl('hourly-service')"
						>
						</bk-service-category>
						<!--Frequency-->
						<div class="position-relative" [style.order]="sectionOrder.frequency">
							<bk-frequency
								[isQuoteEditable]="isQuoteEditable"
								[pageSett]="pageSett"
								[bookingType]="bookingType"
								[section]="section?.frequency"
								[frequencyForm]="BKFrm"
								[selectedLocation]="selectedLocation"
								[selectedServiceType]="selectedServiceType"
								[selectedFrequency]="selectedFrequency"
								[settings]="settings"
								[availableSett]="availableSett"
								[prefilledData]="prefilledData"
								(frequencyChange)="frequencyChange($event, true)" >
							</bk-frequency>
							<!-- Loader -->
							<bk-app-loader [loaderId]="loaderIds?.frequency"></bk-app-loader>
						</div>
						<!-- Form Params -->
						<div [ngSwitch]="formId" [style.order]="sectionOrder.pricing_parameter">
							<ng-container *ngSwitchCase="1">
								<bk-pricing-parameter
									[isQuoteEditable]="isQuoteEditable"
									[pageSett]="pageSett"
									[bookingType]="bookingType"
									[section]="section?.pricing_params"
									[pricingParamForm]="BKFrm"
									[selectedLocation]="selectedLocation"
									[selectedServiceType]="selectedServiceType"
									[selectedFrequency]="selectedFrequency"
									[settings]="settings"
									[availableSett]="availableSett"
									[prefilledData]="prefilledData"
									[prefilledParams]="prefilledFormParams?.pricing_param"
									[prefilledExcludes]="prefilledFormParams.excludes"
									(pricingParamChange)="resetFormControl('pricing-param')"
									(excludeChange)="resetFormControl('exclude')"
									(pricingParamsStatus)="buildFormParamsStatus.pricingParams = $event"
								>
								</bk-pricing-parameter>
							</ng-container>
							<ng-container *ngSwitchCase="2">
								<bk-items-with-pckgs
									[isQuoteEditable]="isQuoteEditable"
									[section]="section?.packages"
									[pageSett]="pageSett"
									[bookingType]="bookingType"
									[itemsForm]="BKFrm"
									[settings]="settings"
									[availableSett]="availableSett"
									[selectedLocation]="selectedLocation"
									[selectedServiceType]="selectedServiceType"
									[selectedFrequency]="selectedFrequency"
									(itemChange)="resetFormControl('items')"
									(itemParamChange)="resetFormControl('items-params')"
									(packageChange)="resetFormControl('package')"
									[prefilledData]="prefilledData"
									[prefilledItems]="prefilledFormParams.items"
									(prefilledItemsChange)="prefilledFormParams.items = $event"
									(itemPckgStatus)="buildFormParamsStatus.itemPackages = $event"
								>
								</bk-items-with-pckgs>
								<!-- Validation -->
								<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
									<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoPackageSelected && !validPckgStatus)" translate>
										<i class="tjsicon-attention"></i>
										<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
										<ng-container *ngIf="isNoPackageSelected && !validPckgStatus">Please select at least one package</ng-container>
									</div>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="3">
								<bk-items-with-addons
									[isQuoteEditable]="isQuoteEditable"
									[section]="section?.addons"
									[bookingType]="bookingType"
									[itemsForm]="BKFrm"
									[pageSett]="pageSett"
									[availableSett]="availableSett"
									[settings]="settings"
									[selectedLocation]="selectedLocation"
									[selectedServiceType]="selectedServiceType"
									[selectedFrequency]="selectedFrequency"
									(itemChange)="resetFormControl('items')"
									(itemParamChange)="resetFormControl('items-params')"
									[prefilledData]="prefilledData"
									[prefilledItems]="prefilledFormParams.items"
									(itemAddonStatus)="buildFormParamsStatus.itemsAddons = $event"
								>
								</bk-items-with-addons>
								<!-- Validation -->
								<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
									<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoAddonSelected && (BKFrm?.value['items']).length == 0)" translate>
										<i class="tjsicon-attention"></i>
										<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
										<ng-container *ngIf="isNoAddonSelected && (BKFrm?.value['items']).length == 0">Please select at least one addon</ng-container>
									</div>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="4">
								<bk-pricing-parameter-with-area *ngIf="BKFrm.value['area_parameter']" #areaParamComp
									[isQuoteEditable]="isQuoteEditable"
									[pageSett]="pageSett"
									[availableSett]="availableSett"
									[bookingType]="bookingType"
									[section]="section?.area_params"
									[areaParamForm]="BKFrm"
									[selectedLocation]="selectedLocation"
									[selectedServiceType]="selectedServiceType"
									[selectedFrequency]="selectedFrequency"
									[settings]="settings"
									[availableSett]="availableSett"
									(areaUnitChange)="resetFormControl('area-params')"
									[prefilledData]="prefilledData"
									[prefilledAreaParam]="prefilledFormParams.area_param"
									[prefilledAreaParam]="prefilledFormParams.area_param"
									(areaParamsStatus)="buildFormParamsStatus.areaParams = $event"
								>
								</bk-pricing-parameter-with-area>
							</ng-container>
						</div>
						<!-- Extras -->
						<div [style.order]="sectionOrder.extras">
							<bk-extras
								[isQuoteEditable]="isQuoteEditable"
								[bookingType]="bookingType"
								[section]="section?.extras"
								[pageSett]="pageSett"
								[extrasForm]="BKFrm"
								[settings]="settings"
								[availableSett]="availableSett"
								[prefilledData]="prefilledData"
								[prefilledExtras]="prefilledFormParams.extras"
								[selectedServiceType]="selectedServiceType"
								[selectedFrequency]="selectedFrequency"
								[selectedLocation]="selectedLocation"
								(extraChange)="resetFormControl('extras')">
							</bk-extras>
						</div>
						<!-- Custom fields -->
						<ng-container *ngIf="bkngCustSecServ.stepWiseCustGrp.step_one && (bkngCustSecServ.stepWiseCustGrp.step_one).length > 0 && bkngCustSecServ.buildCustomFields">
							<ng-container *ngFor="let customField of bkngCustSecServ.stepWiseCustGrp.step_one">
								<bk-custom-fields [style.order]="sectionOrder['custom_fields_'+customField._id]"
									[customFieldGroup]="customField"
									[bookingType]="bookingType"
									[BKFrm]="BKFrm"
									[prefilledData]="prefilledData"
									stepType="all"
									[selectedServiceType]="selectedServiceType"
									[buildCustomFields]="bkngCustSecServ.buildCustomFields"
									(customFieldChange)="resetFormControl('custom-fields', $event)"
								></bk-custom-fields>
							</ng-container>
						</ng-container>
						<!-- Customer email and details -->
						<bk-customer-email [style.order]="sectionOrder.email" #emailComp
						*ngIf="((settings && settings?.form_data && settings?.form_data?.enable_email_on_first_step && settings?.form_data?.enable_email_on_first_step != 'no') || !settings?.form_data?.enable_email_on_first_step || (BKFrm.controls['uid'].value && !currentUser))"
							[settings]="settings"
							[pageSett]="pageSett"
							[currentUser]="currentUser"
							[customerForm]="BKFrm"
							[section]="section?.email"
							[formLayout]="formLayout"
							(loginExistingUser)="loginUser(false)"
							(custmrChng)="custmrChng()"
						>
						</bk-customer-email>
					</div>
				</div>
				<bk-navigate [secSett]="section?.save_step_one_btn" innerHTML="<i class='tjsicon-calendar-1 me-10'></i> {{section?.save_step_one_btn?.content}}" customClass="btn btn-primary btn-lg fs-24 rounded-5 border-transparent" essentialClass="w-100 d-flex justify-content-center align-items-center" (callback)="submitStepOne()"></bk-navigate>
			</div>
			<!-- Second step -->
			<div [ngClass]="{'d-none': !isStepOneValid}">
				<bk-navigate [secSett]="section?.go_back_to_step_one_btn" innerHTML="<i class='tjsicon-left-arrow-1 me-10'></i> {{section?.go_back_to_step_one_btn?.content}}" customClass="btn btn-primary btn-lg border-transparent" (callback)="goToStepOne()" essentialClass="mb-30 d-flex justify-content-center align-items-center"></bk-navigate>
				<div [id]="section?.one_step_form"  *ngIf="twoStepFormSections && twoStepFormSections.step_two && (twoStepFormSections.step_two).length > 0" class="tjs-card card border rounded-5 tjs-rounded--lg mb-30 bk-card">
					<div class="card-body py-0 d-flex flex-column">
						<!-- Service provider -->
						<ng-container *ngIf="!isQuote || (isQuote && prefilledData)">
							<bk-service-provider
								[isQuote]="isQuote"
								[ServiceProviderControl]="BKFrm"
								[prefilledData]="prefilledData"
								[pageSett]="pageSett"
								[style.order]="sectionOrder.service_provider"
								[section]="section?.service_providers"
								[calSection]="section?.calendar"
								[settings]="settings"
								[dayDiscountApplyToAll]="dayDiscountApplyToAll"
								[dayDiscountBookings]="dayDiscountBookings"
								[dateDiscountsArray]="dateDiscountsArray"
								[dayDiscountsArray]="dayDiscountsArray"
								[dayDiscounts]="dayDiscounts"
								(onBookingDateChange)="onBookingDateChange()"
								(onSpotChangeCallback)="onSpotChange()"
								(resetPrvdrOverriddenTime)="resetPrvdrOverriddenTime()"
								>
							</bk-service-provider>
						</ng-container>
						<!-- Tip/parking -->
						<bk-tip-parking [style.order]="sectionOrder.tip_parking"
							[bookingType]="bookingType"
							[tipParkingForm]="BKFrm"
							[pageSett]="pageSett"
							[selectedServiceType]="selectedServiceType"
							[section]="section?.tip_parking"
							[locationType]="BKFrm.value['location_type']"
							[prefilledData]="prefilledData"
						>
						</bk-tip-parking>
						<ng-container *ngIf="buildAddressStatus">
							<!-- Customer details -->
							<bk-customer-details [style.order]="sectionOrder.customer_details" #customerComp
								[currentUser]="currentUser"
								[isQuote]="isQuote"
								[settings]="settings"
								[bookingType]="bookingType"
								[pageSett]="pageSett"
								[zipcode]="zipcodeValue"
								[customerForm]="BKFrm"
								[section]="section"
								[prefilledData]="prefilledData"
								[locationLayout]="locationLayout"
								[selectedLocation]="selectedLocation"
								[locationType]="BKFrm.value['location_type']"
								[customerDetails]="customerDetails"
								(loginExistingUser)="loginUser(false)"
								(zipCodeChange)="reCalculateTax($event)"
								(custmrChng)="custmrChng()"
								(custDetailsChng)="custDetailsFocusOut()"
							>
							</bk-customer-details>
							<!-- Address -->
							<bk-address [style.order]="sectionOrder.address_details" *ngIf="BKFrm.value['location_type'] == 'SA' && (section.customer_details.design_id == 'bk_customer_v3' || section.customer_details.design_id == 'bk_customer_v4')"
								[currentUser]="currentUser"
								[isQuote]="isQuote"
								[settings]="settings"
								[bookingType]="bookingType"
								[section]="section.address_details"
								[custSection]="section.customer_details"
								[pageSett]="pageSett"
								[addressForm]="BKFrm"
								[prefilledData]="prefilledData"
								[locationLayout]="locationLayout"
								[zipcode]="zipcodeValue"
								[selectedLocation]="selectedLocation"
								[addresses]="customerDetails?.addresses"
								(zipCodeChange)="reCalculateTax($event)"
								[locationType]="BKFrm.value['location_type']"
							>
							</bk-address>
						</ng-container>
						<!-- Key info -->
						<bk-key-info [style.order]="sectionOrder.key_info" *ngIf="BKFrm.value['location_type'] == 'SA'"
							[bookingType]="bookingType"
							[section]="section.key_info"
							[prefilledData]="prefilledData"
							[pageSett]="pageSett"
							[KeyInfoForm]="BKFrm">
						</bk-key-info>
						<!-- Special note -->
						<bk-special-notes
							[style.order]="sectionOrder.special_notes"
							[bookingType]="bookingType"
							[section]="section.special_note"
							[noteForm]="BKFrm"
							[pageSett]="pageSett"
							[prefilledData]="prefilledData">
						</bk-special-notes>
						<!-- Discount -->
						<bk-discounts *ngIf="couponPerm"
							[style.order]="sectionOrder.discounts"
							[bookingType]="bookingType"
							[pageSett]="pageSett"
							[section]="section.discount"
							[discountForm]="BKFrm"
							[referralDiscount]="BKFrm.value?.referral_discount"
							[settings]="settings"
							[prefilledData]="prefilledData"
							[selectedServiceType]="selectedServiceType"
							(couponApply)="couponApply($event)"
							(resetCoupon)="resetCoupon($event)"
							(checkPreChargeIfCoupon)="checkPreChargeIfCoupon($event)"
							(giftCardApply)="giftCardSuccessfullyApplied($event)"
							(resetGiftCard)="resetGiftCard($event)"
							(resetReferralUser)="resetReferralUser($event)"
							(referralApply)="referralApply()"
							(resetReferral)="resetReferral($event)"
							>
						</bk-discounts>
						<!-- Custom fields -->
						<ng-container *ngIf="bkngCustSecServ.stepWiseCustGrp.step_two && (bkngCustSecServ.stepWiseCustGrp.step_two).length > 0 && bkngCustSecServ.buildCustomFields">
							<ng-container *ngFor="let customField of bkngCustSecServ.stepWiseCustGrp.step_two">
								<bk-custom-fields [style.order]="sectionOrder['custom_fields_'+customField._id]"
									[customFieldGroup]="customField"
									[bookingType]="bookingType"
									[BKFrm]="BKFrm"
									[prefilledData]="prefilledData"
									stepType="all"
									[selectedServiceType]="selectedServiceType"
									[buildCustomFields]="bkngCustSecServ.buildCustomFields"
									(customFieldChange)="resetFormControl('custom-fields', $event)"
								></bk-custom-fields>
							</ng-container>
						</ng-container>
						<!-- Payment information -->
						<bk-payment-info
							*ngIf="buildAddressStatus"
							[style.order]="sectionOrder.payment_info"
							[pageSett]="pageSett"
							[currentUser]="currentUser"
							[bookingType]="bookingType"
							[section]="section.payment_info"
							[paymentForm]="BKFrm"
							[selectedServiceType]="selectedServiceType"
							[prefilledData]="prefilledData"
							[selectedLocation]="selectedLocation"
							[locationLayout]="locationLayout"
							[cards]="customerDetails?.cards"
							>
						</bk-payment-info>
					</div>
				</div>
				<!-- Save booking btn -->
				<bk-common-buttons
					[bookingType]="bookingType"
					[settings]="settings"
					[BKFrm]="BKFrm"
					[pageSett]="pageSett"
					[section]="section"
					[acceptTcStatus]="acceptTcStatus"
					[isAcceptTcValid]="isAcceptTcValid"
					(acceptTcChange)="isAcceptTcValid = true"
					(submitBKFrm)="submitBKFrm()">
				</bk-common-buttons>
			</div>
		</div>
		<!-- Sidebar -->
		<bk-sidebar class="col-12 col-sm-12 col-md-12 col-lg-4 sticky-container" [ngStyle]="{'margin-top':sidebarOffset}"
			[sidebarSequence]="summarySequence"
			[sidebarSecOrder]="sidebarSecOrder"
			[BKFrm]="BKFrm"
			[section]="section"
			[pageSett]="pageSett"
			[bookingType]="bookingType"
			[settings]="settings"
			[industryId]="industryId"
			[formId]="formId"
			[formSett]="formSett"
			[couponData]="couponData"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[selectedPricingParams]="formId == 1 && BKFrm.value['pricing_parameter']"
			[selectedItems]="(formId == 2 || formId == 3) && BKFrm.value['items']"
			[selectedAreaParams]="formId == 4 && BKFrm.value['area_parameter']"
			[selectedExtras]="BKFrm.value['extras']"
			[selectedExcludes]="formId == 1 && BKFrm?.value['is_partial_cleaning'] && BKFrm.value['partial_cleaning']"
			[adjustedTimeHours]="priceLocalVar?.adjustedTimeHours"
			[adjustedTimeMin]="priceLocalVar?.adjustedTimeMin"
			[bookingTimeHours]="priceLocalVar?.bookingTimeHours"
			[bookingTimeMin]="priceLocalVar?.bookingTimeMin"
			[selectedAddr]="BKFrm?.value['address']"
			[isServiceFeeTaxable]="priceLocalVar?.isServiceFeeTaxable"
			[beforePriceableFieldsPrice]="BKFrm?.value['before_priceable_fields_price']"
			[includeInFreqFieldsPrice]="BKFrm?.value['include_in_freq_fields_price']"
			[exemptFromFreqFieldsPrice]="BKFrm?.value['exempt_from_freq_fields_price']"
			[nonTaxableFieldsPrice]="BKFrm?.value['non_taxable_fields_price']"
			[afterDiscTotalFieldsPrice]="BKFrm?.value['after_disc_total_fields_price']"
			[displayServicePrice]="priceLocalVar?.displayServicePrice"
			[excludeExpeditedAmountValue]="priceLocalVar?.excludeExpeditedAmountValue"
			[displayFrequencyDiscount]="priceLocalVar?.displayFrequencyDiscount"
			[displayCouponDiscount]="priceLocalVar?.displayCouponDiscount"
			[displayDiscountedAmount]="priceLocalVar?.displayDiscountedAmount"
			[bookingTaxRate]="priceLocalVar?.bookingTaxRate"
			[exemptSalesTaxValue]="priceLocalVar?.exemptSalesTaxValue"
			[bookingTaxType]="priceLocalVar?.bookingTaxType"
			[displaySaleTax]="priceLocalVar?.displaySaleTax"
			[displayTipsAmount]="priceLocalVar?.displayTipsAmount"
			[excludeServiceFeeValue]="priceLocalVar?.excludeServiceFeeValue"
			[displayServiceFee]="priceLocalVar?.displayServiceFee"
			[displayTotal]="priceLocalVar?.displayTotal"
			[firstRecAptFinalAmount]="priceLocalVar?.firstRecAptFinalAmount"
			[afterNthAptFinalAmount]="priceLocalVar?.afterNthAptFinalAmount"
			[displaySpotDiscount]="priceLocalVar?.displaySpotDiscount"
			[displayGiftCardAmount]="priceLocalVar?.displayGiftCardAmount"
			[displayReferralDiscount]="priceLocalVar?.displayReferralDiscount"
			[displayFinalAmount]="priceLocalVar?.displayFinalAmount"
			[formMetaData]="formMetaData"
			[settingsObj]="settingsObj"
		>
		</bk-sidebar>
	</form>
</ng-container>
<ng-template #underMaintenance>
	<div class="pt-30 tjs-section--space-md mb-30 col-lg-8 px-0">
		<div class="card">
			<div class="card-body">
				<h3 class="mb-0" *ngIf="settings">{{initServ?.appStr?.text?.undergoingMaintenance | translate}}</h3>
			</div>
		</div>
	</div>
</ng-template>
