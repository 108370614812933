import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';
import { HttpResponse } from '@angular/common/http';
import { decryptGCM, encryptGCM } from 'iron-crypto-pkg';
import { environment as env } from 'src/environments/environment';

const DB_NAME = 'qwergnirihzxcv'; // "d_YXBw"
const STORE_NAME = 'lkjhmrofzxcv'; // "s_k4df2c"s
@Injectable({
	providedIn: 'root'
})
export class CacheService {


	private dbPromise: Promise<IDBPDatabase>;

	constructor() {
		this.dbPromise = openDB(DB_NAME, 1, {
			upgrade(db) {
				if (!db.objectStoreNames.contains(STORE_NAME)) {
					// Fix: Explicitly set the keyPath to "url"
					db.createObjectStore(STORE_NAME);
				}
			}
		});
	}

	private cacheableUrls: RegExp[] = [
		// new RegExp(`${env.baseUrl}/api/v\\d+/appload-customer`),
		// new RegExp(`${env.baseUrl}/api/v\\d+/admin-settings-customer`),
		// new RegExp(`${env.baseUrl}/bktheme/v\\d+/site/data\\?theme_slug=([a-zA-Z0-9_-]*)`),
		// new RegExp(`${env.baseUrl}/bktheme/v\\d+/site/design-settings`),
		// new RegExp(`${env.baseUrl}/api/v\\d+/all-booking-spots\\?customer=(true|false)`),
		// new RegExp(`${env.baseUrl}/api/v\\d+/industry/\\d+/form-settings`),
		// new RegExp(`${env.baseUrl}/api/v\\d+/merchant/form-settings/\\d+/\\d+/settings`),
		// new RegExp(`${env.baseUrl}/bktheme/v\\d+/page/\\d+\\?slug=[a-zA-Z0-9_-]+(&language=[a-z]{2})?(&industry=\\d+)?(&form=\\d+)?(&mode=(live|preview))?(&theme_slug=.*)?$`),
		// new RegExp(`${env.baseUrl}/bktheme/v\\d+/page/\\d+/style\\?slug=[a-zA-Z0-9_-]+(&language=[a-z]{2})?(&industry=\\d+)?(&form=\\d+)?(&mode=(live|preview))?(&theme_slug=.*)?$`),
		// new RegExp(`${env.baseUrl}/bktheme/v\\d+/page/\\d+/(style|page)/\\d+\\?slug=[a-zA-Z0-9_-]+(&language=[a-z]{2})?(&mode=(live|preview))?(&theme_slug=.*)?$`)
	];

	/**
	* Determines if a URL should be cached.
	*/
	public isCacheable(url: string): boolean {
		return this.cacheableUrls.some(pattern => pattern.test(url));
	}

	/**
	 * Retrieves a cached response from IndexedDB.
	 * @param url API request URL.
	 * @returns Cached HttpResponse or null if not found.
	 */
	async get(url: string): Promise<HttpResponse<any> | null> {
		const db = await this.dbPromise;
		const hashedUrl = await this.hashUrl(url);
		const cached = await db.get(STORE_NAME, hashedUrl);

		if (!cached) return null;

		// Check expiration
		try{
			if (!cached.body) throw new Error('No data found in cache');

			const decryptedBody = await decryptGCM(cached.body, env.satyaNew + env.tretaNew + env.dvaearaNew + env.kaliNew, true);
			if (!decryptedBody) throw new Error('Decryption failed, returned empty data');

			const now = Date.now();
			if (cached.expiry && cached.expiry < now) {
				await db.delete(STORE_NAME, hashedUrl);
				return null;
			}

			return new HttpResponse({
				body: JSON.parse(decryptedBody),
				status: 200
			});
		}catch(error){
			console.error('Decryption failed:', error);
			return null;
		}
	}

	/**
	 * Saves an API response in IndexedDB.
	 * @param url API request URL.
	 * @param response API response to be cached.
	 * @param ttl Time-To-Live (Expiration in milliseconds, default 5 minutes) //86400000
	 */
	async set(url: string, response: HttpResponse<any>, ttl: number = 900000): Promise<void> {
		const db = await this.dbPromise;
		const hashedUrl = await this.hashUrl(url);
		const expiry = Date.now() + ttl; // Set expiration default: 5 minutes

		try {
			const encryptedBody = await encryptGCM(JSON.stringify(response.body), env.satyaNew + env.tretaNew + env.dvaearaNew + env.kaliNew, true);
			if (!encryptedBody) throw new Error('Encryption returned empty string');

			await db.put(STORE_NAME, {
				body: encryptedBody,
				expiry
			}, hashedUrl);
		}catch (error) {
			console.error('Encryption failed:', error);
		}
	}

	/**
	 * Clears all cached responses from IndexedDB.
	 */
	async clear(): Promise<void> {
		const db = await this.dbPromise;
		await db.clear(STORE_NAME);
	}

	/**
	 * Removes a specific API response from IndexedDB cache.
	 * @param url API request URL to remove from cache.
	 */
	async remove(url: string): Promise<void> {
		const db = await this.dbPromise;
		const hashedUrl = await this.hashUrl(url);
		await db.delete(STORE_NAME, hashedUrl);
	}

	/** Generate a SHA-256 hash of the URL */
	private async hashUrl(url: string): Promise<string> {
		const encoder = new TextEncoder();
		const data = encoder.encode(url);
		const hashBuffer = await crypto.subtle.digest('SHA-256', data);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
	}
}
